
export enum PortalLinkType
{
    InvoicePayment=1,
    PaymentDeposit=2
}
export class LinkData{
    public code:String;
    public id:String;
    public type:PortalLinkType
    public clientId:String;
    public clientName:String
    public tenantId:String;
}
export class LinkValidationResult{
    public isValid:Boolean;
    public linkData:LinkData
  
}