<template>
    <PrePaymentGatewayHandler @paymentreceived="onPaymentReceived" v-if="prepaymentToken" :paymentToken="prepaymentToken"/>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PrePaymentGatewayHandler from '@/components/Payments/PrePaymentGatewayHandler.vue'
import { invoiceService } from '@/utils/http';
@Component({
  components: {
  PrePaymentGatewayHandler
  },
})
export default class AuthenticatedPrepaymentsPage extends Vue{
  prepaymentToken='';
 created(){
  invoiceService.getClientPrepaymentToken().then(result=>{
    this.prepaymentToken=result;
  })
 }
 onPaymentReceived(){
  this.$router.push({name:'depositreceived'});
 }
}
</script>