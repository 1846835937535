<template>
   <div class="container h-100">
    <div class="row">
        <div class="mt-5 col-12 d-flex justify-content-center flex-nowrap">
          <div>
            <i class="fa fa-check-circle" style="font-size: 100px; color: green;width:100px"></i>
          </div>
          
        </div>
        <div class="mt-5 col-12 d-flex justify-content-center flex-nowrap">
         <span> Thank You. Your payment has been received.</span>
          
        </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {
  
  },
})
export default class AutenticatedDepositReceived extends Vue{
 created(){
 
 }

}
</script>