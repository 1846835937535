<template>
    <FileList v-bind="$props"/>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import FileList from '@/components/Files/FileList.vue'
@Component({
  components: {
  FileList
  },
})
export default class FileListPage extends Vue{
 
  
}
</script>