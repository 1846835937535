<template>
  <b-card title="Upload Files">
    <b-form-file
      multiple
      v-model="pickerFiles"
      @change="filePickerChanged($event)"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
    ></b-form-file>
    <!-- <FileUploader :folderId="folderId" @uploaded="fileUploaded(file.name)" @remove="removeItem(file.name)" :key="file.name" v-for="file in files" :file="file"/> -->
  </b-card>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { BlobServiceClient } from "@azure/storage-blob";

import { fileService } from "@/utils/http";
import FileUploader from "@/components/Files/common/FileUploader.vue";
@Component({
  components: {
    FileUploader,
  },
})
export default class FilePicker extends Vue {
  @Prop({ required: false })
  folderId: "";
  pickerFiles: [] = [];
  files: File[] = [];

  mounted() {}
  fileUploaded(name) {
    this.$emit("fileuploaded");
    this.removeItem(name);
  }
  removeItem(name) {
    this.files.splice(
      this.files.findIndex((f) => f.name == name),
      1
    );
  }
  filePickerChanged(event) {
    var files = [...event.target.files];
    this.files = files;
    this.pickerFiles = [];
  }
  created() {}
}
</script>