<template>
    <b-input-group  size="sm">
   
     
   <b-dropdown  variant="link"  size="sm"  >
    <template #button-content>
     Sort By:  <strong class="ml-2">{{ selectedSortColumn.label }}</strong> 
    </template>
    <b-dropdown-item v-for="column in sortColumns" @click="setSortColumn(column)"   :key="column.property">{{column.label}}</b-dropdown-item>
  
  </b-dropdown>
  <b-dropdown variant="link"     size="sm"  :text="selectedSortOrder.label">
    <template #button-content>
     <strong >{{ selectedSortOrder.label }}</strong> 
    </template>
    <b-dropdown-item v-for="order in sortOrders" @click="setSortOrder(order)"  :key="order.value">{{order.label}}</b-dropdown-item>

  
  </b-dropdown>
    
   
  </b-input-group>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
  },
})
export default class FileFilterComponent extends Vue {
  sortColumns= [
        {label:'Name', property: 'name', type: 'string' },
        {label:'Date', property: 'createdOn', type: 'date' }
      ]
      sortOrders= [
        {label:'Asc', value: 'asc',  },
        {label:'Desc', value: 'desc' }
      ]
   selectedSortColumn= this.sortColumns[0];
   selectedSortOrder=this.sortOrders[0]

   created(){
    this.emitChanges();
   }
   setSortColumn(column){
    this.selectedSortColumn=column;
    this.emitChanges();
   }
   setSortOrder(order){
    this.selectedSortOrder=order
    this.emitChanges();
   }
   emitChanges(){
    this.$emit('onSort',{
      column:{property:this.selectedSortColumn.property, type:this.selectedSortColumn.type},
      order:this.selectedSortOrder.value
    })
   }
}
</script>

<style scoped>

</style>