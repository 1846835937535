import './polyfills'
// // A plugin file where you could register global components used across the app
// import GlobalComponents from './globalComponents'
// // A plugin file where you could register global directives
// import GlobalDirectives from './globalDirectives'
// // Sidebar on the right. Used as a local plugin in DashboardLayout.vue

import SideBar from './components/sidebar'
import  GlobalComponents from './global-components'
import  GlobalDirectives from './global-directives'


// asset imports
import './assets/sass/light-bootstrap-dashboard.scss'

export default {
  async install (Vue) {
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    Vue.use(SideBar)
    // Vue.use(NotificationPlugin)
    // Vue.use(VueTabs)
  }
}
