<template>
  <b-skeleton-wrapper :loading="isLoading">
    <template #loading>
      <b-card class="skeleton-card" v-for="index in 3" :key="index">
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </b-card>
    </template>

   
  </b-skeleton-wrapper>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { invoiceService } from "@/utils/http";
import ContentLayout from "@/components/layouts/ContentLayout.vue";
import { messageService } from "@/utils";

@Component({
  components: {
    ContentLayout,
  },
})
export default class PayClixPaymentReceived extends Vue {
  @Prop({ required: true })
  public route: any;
  isLoading = true;
 


  created() {
   this.synchornizePayments();
  }
 
 
  synchornizePayments() {
    messageService.showLoading('Please wait while we process the payment..')
     invoiceService.synchornizePayclix().then(()=>{
      
     }).catch(err=>{
       
     }).finally(()=>{
 messageService.closeDialogs();
       this.$router.push(this.route)
     })
  }
}
</script>
<style scoped>

</style>