<template>
  <div class="container-fluid h-100">
    <SidebarRouterView />
    <div class="row no-gutters h-100">
      <div class="col-12 h-100">
        <ContentLayout>
          <template #header>
            <b-badge @click="iStatus.isChecked = !iStatus.isChecked" :variant="iStatus.isChecked ? 'success' : 'light'"
              class="mr-1" style="cursor:pointer" v-for="iStatus in invoiceStatuses" :key="iStatus.label">
              <i :style="{ 'color': iStatus.color }" class="fa fa-circle"></i> {{ iStatus.label }}
            </b-badge>
            <b-button class="mr-1" variant="link" size="sm" @click="loadData"><i class="fa fa-refresh"></i></b-button>


          </template>
          <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-card class="skeleton-card" v-for="index in 3" :key="index">
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
              </b-card>
            </template>
            <template v-if="filteredInvoices.length > 0">
                  <InvoiceDetails :hidePay="selectedIvoices.length>0" @pay="navigateToPayment" v-for="invoice in filteredInvoices" :key="invoice.invoiceId"
                    :invoice="invoice" />
                </template>
                <b-card v-else title="No Invoices">
                  <b-card-sub-title>
                    Try changing your filters
                  </b-card-sub-title>
                  <b-card-text>
                    You don't seem to have any invoices.
                  </b-card-text>
                </b-card>

          </b-skeleton-wrapper>
          <!-- <button type="button" id="multi-pay" :key="selectedIvoices.length" v-if="selectedIvoices.length > 0" class="btn btn-primary btn-circle floating-button animate__animated  animate__bounce">
         Pay  <b-badge variant="light">
           {{ totalSelectedInvoicesAmountToPay | money }}
           </b-badge>
        
          </button> -->
          <b-button  size="lg" @click="paySelectedInvoices" variant="success" v-if="selectedIvoices.length>0" :key="selectedIvoices.length" class=" floating-button animate__animated  animate__bounce">
           
            Pay  {{ selectedIvoices.length }} Invoices <b-badge variant="light">  {{ totalSelectedInvoicesAmountToPay | money }}</b-badge>

          
          </b-button>
         

         
        </ContentLayout>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { invoiceService } from "@/utils/http";
import ContentLayout from "@/components/layouts/ContentLayout.vue";

import CardInfo from "../Payments/common/CardInfo.vue";
import InvoiceDetails from "./common/InvoiceDetails.vue";
import { messageBuss } from "@/utils/";

@Component({
  components: {
    ContentLayout,
    CardInfo,
    InvoiceDetails,
  },
})
export default class Invoices extends Vue {
  isLoading = false;
  showSelectedInvoices=false;
  invoices: any[] = [];
  invoiceStatuses: { label; isChecked, color, values }[] = [
    // {
    //   label: "All",
    //   isChecked: false,
    //   color:'black',
    //   values:[41,42,43,218]
    // },
    {
      label: "UnPaid",
      isChecked: true,
      color: 'yellow',
      values: [41, 43]//unpaid and partially paid
    },


    {
      label: "Paid",
      isChecked: false,
      color: 'green',
      values: [42]
    },
    {
      label: "Voided",
      isChecked: false,
      color: 'red',
      values: [218]
    },
  ];

  get filteredInvoices() {
    let statuses = this.invoiceStatuses.filter(z => z.isChecked).flatMap(z => z.values);
    if (statuses.length == 0) return this.invoices;

    return this.invoices.filter(z => statuses.includes(z.status));
  }
  get selectedIvoices() {
    return this.invoices.filter(z => z.selected);
  }
  get totalSelectedInvoicesAmountToPay() {
    return this.selectedIvoices.reduce((a, b) => a + b.unpaidAmount, 0);
  }
  created() {
    messageBuss.onEvent(messageBuss.eventTypes.PaymentApplied, this.paymentApplied);
    messageBuss.onEvent(messageBuss.eventTypes.FirmChanged, this.reset);

    this.loadData();
  }
  beforeDestroy() {
    messageBuss.offEvent(
      messageBuss.eventTypes.PaymentApplied,
      this.paymentApplied
    );
    messageBuss.offEvent(messageBuss.eventTypes.FirmChanged, this.reset);

  }
  reset() {
    this.invoices = [];
    this.loadData();
  }
  paymentApplied() {
    this.$router.replace("/app/invoices");
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    invoiceService.getInvoices().then((invoices) => {
      invoices.forEach(x => {
        {
          x.selected = false;
        }
      })
      this.invoices = invoices;
      this.isLoading = false;
    });
  }
  navigateToPayment(invoiceId) {
    invoiceService.getInvoiceToken(invoiceId).then((token) => {
      this.$router.push(`invoicepayment/${token}`);
    });
  }
  paySelectedInvoices() {
    invoiceService.getInvoicesToken(this.selectedIvoices.map(z => z.invoiceId)).then((token) => {
      this.$router.push(`invoicepayment/${token}`);
    });
  }
}
</script>
<style scoped>
.skeleton-card {}

.invoice-status {
  position: absolute;
  top: 5px;
  right: 5px;
}
.floating-button{
  position: fixed;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}


 
</style>