let element = document.createElement("a");
     


class FileUtils {
  
    downloadFileFromLink(link:string){
        element.setAttribute("href", link);
        element.setAttribute("target", "_blank");
        element.setAttribute("download", '');
  
        // Above code is equivalent to
        // <a href="path of file" download="file name">
  
        document.body.appendChild(element);
  
        //onClick property
        element.click();
  
        document.body.removeChild(element);
    }
}
export const fileUtils = new FileUtils();
