<template>
 
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { fileService } from "@/utils/http";
import { fileUtils } from "@/utils";
import imageZoom from "vue-image-zoomer";
import { messageBuss, messageService } from "@/utils";

@Component({
  components: {
    imageZoom,
  },
})
export default class FileItem  extends Vue {
  @Prop({ default: null, required: true })
  file: any;
   @Prop({ default: false, required: true })
  showSelection: boolean;
   @Prop({ default: false, required: false })
  showNavigateToFolder: boolean;

  download() {
    fileService.getDownloadLink(this.file.id).then((link) => {
     fileUtils.downloadFileFromLink(link)
    });
  }
  tryLoadThumbnail(retryCount=0){
   
    if(!this.file.canPreview) return;
    if(this.file.thumbnailUrl) return;
    if(retryCount>10){
      Vue.set(this.file,'thumbnailGenerationFailed',true)
      return;
    }
    fileService.getThumbnailLink(this.file.id ).then(link=>{
      if(!link){
       setTimeout(()=>this.tryLoadThumbnail(retryCount+1),1000 * 20)//retry after 20 seconds;
       return;
      }
      else{
        this.file.thumbnailUrl=link;
        Vue.set(this.file,'thumbnailGenerationFailed',false)
      }
    })
  }
  getImageForExtension(extension: string) {
    let imgName = "generic";
    switch (extension) {
      case "pdf":
      case "ps":
      case "eps":
        imgName = "pdf";
        break;
      case "doc":
      case "docx":
      case "docm":
        imgName = "doc";
        break;
      case "xlsx":
      case "xlsm":
      case "xlsb":
      case "xltx":
        imgName = "xls";
        break;
      case "odt":
      case "rtf":
      case "tex":
      case "wpd":
      case "txt":
        imgName = "txt";
        break;
    }
    return `static/img/fileextensions/${imgName}.png`;
  }
  async deleteFile(){
    var confirmation= await messageService.confirm("Confirmation","Are you sure you want to delete this file? You can find the file in the trash folder for up to 30 days after deleting it.","Yes, delete it")
   if(!confirmation) return;
  await fileService.deleteFile(this.file.id)
  messageBuss.triggerEvent(messageBuss.eventTypes.FileDeleted,this.file.id)
  }
  async deleteFolder(){
    var canDelete= await fileService.canDeleteFolder(this.file.id);
    if(!canDelete){
      return messageService.showWarning("Please clear the folder first before you can delete it");
    }
  await fileService.deleteFolder(this.file.id)
  messageBuss.triggerEvent(messageBuss.eventTypes.FolderDeleted,this.file.id)
  }
 async  renameFolder(){
 var folderName = await messageService.askTextInput(
      "Rename Folder",
      "Folder Name",
      true,
      this.file.name
    );
    if (!folderName) return;
    await fileService.updateFolder(this.file.id,{name:folderName});
    messageBuss.triggerEvent(messageBuss.eventTypes.FolderUpdated,this.file.id)
 }

   async restoreFile(){
    var confirmation= await messageService.confirm("Confirmation","Are you sure you want to restore this file?","Yes, restore it")
   if(!confirmation) return;
  await fileService.restoreFile(this.file.id)
  messageBuss.triggerEvent(messageBuss.eventTypes.FileRestored,this.file.id)
  }
  async permanentDelete(){
    var confirmation= await messageService.confirm("Confirmation","Are you sure you want to permanently delete this file?","Yes")
   if(!confirmation) return;
  await fileService.deleteFile(this.file.id,true)
  messageBuss.triggerEvent(messageBuss.eventTypes.FilePermanentDeleted,this.file.id)
  }
  get canDelete(){
    return this.file && this.file.permisisons && this.file.permisisons.canDelete
  }
  get canDownload(){
    return this.file && this.file.permisisons && this.file.permisisons.canDownload
  }
  get canEdit(){
    return this.file.isSelf
  }
  created(){
   
  }
}
</script>

<style scoped>
.file-item {
  max-width: 300px;
  min-width: 200px;
  margin: 5px;
}
.file-item .card-body .card-subtitle {
  margin-top: 10px;
}
.folder-item {
  max-width: 200px;
  min-width: 200px;
  margin: 5px;
  /* pointer-events:calc(); */
}
.folder-icon {
  top: 5px;
  right: 5px;
  position: absolute;
}
.share-mode-badge {
  position: absolute;
  top: 0px;
  right: 2px;
}
.no-preview-img{
  border:none;
  opacity: 0.5;
  padding:50px
}
</style>