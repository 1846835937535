import {httpService} from '@/utils/http';
import Resources from '@/common/resources';

class PayPalService {
 
  
  public createInvoiceOrder(paymentIntentId): Promise<string> {
    return httpService.postWithNoAuth(`${Resources.payPalPayment}/createpayment/${paymentIntentId}`,{});
  }
  public createPrepaymentOrder(tenantId,clientId,amount): Promise<string> {
    return httpService.postWithNoAuth(`${Resources.payPalPayment}/createprepayment`,{
      tenantId:tenantId,
      clientId:clientId,
      amount:amount
    });
  }
  public executeOrder(paymentId,payerId): Promise<any> {
    return httpService.postWithNoAuth(`${Resources.payPalPayment}/executepayment`,{paymentId:paymentId,payerId:payerId});
  }
  
  
 
  
}
export const payPalService = new PayPalService();
