<template>
    <RescentFiles v-bind="$props"/>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import RescentFiles from '@/components/Files/Rescent.vue'
@Component({
  components: {
  RescentFiles
  },
})
export default class RescentFilesPage extends Vue{
 
  
}
</script>