
import Vue from 'vue'
import Swal from 'sweetalert2'
import { invoiceService } from './http'
class MessageService {
     toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    showToastInfo(message:string){
      this.toast.fire({
          title: message
        })
      }
      showToastError(message:string){
        this.toast.fire({
            icon: 'error',
            title: message
          })
        }
        async askTextInput(
          title: string,
          label: string,
          isRequired = false,
          defaultValue = ""
        ) {
          const { value: inputValue } = await Swal.fire({
            title: title,
            input: "text",
            inputLabel: label,
            inputValue: defaultValue,
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value && isRequired) {
                return "Please provide a value";
              }
            },
          });
      
          return inputValue;
        }
         async confirm(
          title: string,
          label: string,
          confirmButtonText='Yes'
        ):Promise<boolean> {
      const result= await  Swal.fire({
        title: title,
        text: label,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmButtonText
      });
      return result.isConfirmed
        }
         showError(title,message) {
          Swal.fire({
            icon: "error",
            title: title,
            text:message
          });
        }
        showSuccess(message) {
          Swal.fire({
            icon: "success",
            title: message,
            showConfirmButton: false,
            timer: 2000,
          });
        }
        showPaymentReceived(receiptId) {
          Swal.fire({
            title: "Payment received successfully. Thank You",
                  //  html:`Here is your receipt code: <b>${receiptId}</b>`,
        icon: 'success',
        showCloseButton: true,
        showCancelButton: false,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Download receipt',
        denyButtonText: `Don't need receipt`,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return  invoiceService.downloadPaymentReceipt(receiptId)
            .then(response => {
              return;
            })
            .catch(error => {
              // Swal.showValidationMessage(
              //   `Failed to generate receipt`
              // )
            })
        }
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        // if (result.isConfirmed) {
        //   invoiceService.downloadPaymentReceipt(receiptId);
        // } else if (result.isDenied) {
         
        // }
      })
        }
        
        showWarning(message) {
          Swal.fire({
            icon: "warning",
            title: message,
            showConfirmButton: false,
            timer: 2000,
          });
        }
        closeDialogs() {
          Swal.close();
        }
        showLoading(message) {
         Swal.fire({
            title: message,
            html: "Please wait...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        }
      
    
    
}
export const messageService = new MessageService();
