<template>
  <router-link
    v-if="file.isFolder"
    custom
    :to="{ name: 'library', params: { folderId: file.id } }"
    v-slot="{ href, navigate }"
  >
    <b-list-group-item 
      @click="navigate"
      :href="href"
      class="flex-column align-items-start"
    >
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">
          <!-- <div v-if="showSelection" class="form-check file-select-checkbox d-inline-block ml-2 mr-2">
            <input
              type="checkbox"
              class="form-check-input"
              @click.stop="file.selected = !file.selected"
              :checked="file.selected"
            />
          </div> -->
          <i class="fa fa-folder-open" style="color: #f8d775"  :class="{'text-success':file.permisisons.canUpload}"></i>
          {{ file.name }}
        </h5>     

        <small>{{ file.createdOn |localdatetime }}</small>
      </div>
    </b-list-group-item>
  </router-link>
  <b-list-group-item @click="file.selected=!file.selected" :variant="file.selected?'info':''" v-else class="flex-column align-items-start pt-4">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">
        <!-- <div class="form-check file-select-checkbox d-inline-block ml-2 mr-2">
          <input
            type="checkbox"
            class="form-check-input"
            @click.stop="file.selected = !file.selected"
            :checked="file.selected"
          />
        </div> -->
        <img :src="getImageForExtension(file.ext)" class="list-file-type" />
        {{ file.name }}
      </h5>
      <small>{{ file.createdOn | localdatetime }}</small>
    </div>

    <div class="share-mode-badge">
      <b-badge variant="primary" v-if="file.isReceived">Added by firm</b-badge>
      <b-badge v-else variant="success">{{file.isSelf?'Added by you':'Added by portal member'}}</b-badge>
    </div>
<div class="w-100" style="min-height:30px">
      <b-button
        size="sm"
        variant="link"
        :disabled="!canDownload"
        @click="download"
        class="card-link  m-0 pull-right"
        ><i class="fa fa-download"></i> Download</b-button
      >
      <b-button class="mr-1" size="sm" v-if="canEdit"   @click.stop="$emit('edit',file.id)" ><i class="fa fa-edit"></i> Edit</b-button>

  <b-button size="sm"  :disabled="!canDownload" v-if="file.deleteable && !file.isDeleted" variant="danger"  @click.stop="deleteFile" ><i class="fa fa-trash"></i> Delete</b-button>
    <b-button size="sm" v-if="file.isDeleted" variant="success"  @click.stop="restoreFile" ><i class="fa fa-undo"></i> Restore</b-button>
   <b-badge class="m-1" v-if="file.deletedOn">Deleted {{ file.deletedOn | localdatetime }}</b-badge>
</div>
  </b-list-group-item>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import imageZoom from "vue-image-zoomer";
import FileItem from "./FileItem.vue";

@Component({
  components: {
    imageZoom,
  },
})
export default class FileItemList extends FileItem {
  created() {}
}
</script>

<style scoped>
.file-select-checkbox > .form-check-input {
  opacity: 1;
  visibility: visible;
}
.list-file-type {
  width: 30px;
  height: 30px;
  display: inline-block;
}
.file-item {
  max-width: 300px;
  min-width: 200px;
  margin: 5px;
}
.file-item .card-body .card-subtitle {
  margin-top: 10px;
}
.folder-item {
  max-width: 200px;
  min-width: 200px;
  margin: 5px;
  /* pointer-events:calc(); */
}
.folder-icon {
  top: 5px;
  right: 5px;
  position: absolute;
}
.share-mode-badge {
  position: absolute;
  top: 0px;
  right: 2px;
}
.no-preview-img {
  border: none;
  opacity: 0.5;
  padding: 50px;
}
</style>