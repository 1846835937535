import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
import { LinkValidationResult } from '@/common/models';
class LinkService {
 
  
  public getLink(code:string): Promise<LinkValidationResult> {
    return httpService.getWithNoAuth(`${Resources.link}/${code}`);
  }
  
  
  
 
  
}
export const linkService = new LinkService();
