<template>
   <div class="container-fluid h-100">
    <div class="d-flex justify-content-center align-items-center h-100">

      <b-card class="mt-3">
         <template v-if="isLoading">
         <b-card-title>
          <b-spinner style="width: 7rem; height: 7rem"></b-spinner>
          <h4>Verifying Link..</h4>
         </b-card-title>
        </template>
        <template v-else-if="sessionDetails">
          <template v-if="sessionDetails.isSuccessfull">
         <b-card-title >
          Payment has been received successfully
         </b-card-title>
         <hr/>
         <b-button variant="success" v-if="sessionDetails.receiptId" @click="generatePaymentReceipt">
          Generate Payment Receipt
         </b-button>
         <b-button class="ml-2" v-if="redirectUrl" variant="link" @click=redirectToPostProcess>
          Continue
        </b-button>
        </template>
        <template v-else>
         <b-card-title class="text-danger" >
          {{sessionDetails.errorMessage}}
         </b-card-title>
         <hr/>
        
         <b-button  v-if="redirectUrl" variant="link" @click=redirectToPostProcess>
          Continue
        </b-button>
      </template>
       
      </template>
   
  </b-card>
     
    </div>
  </div>

</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { invoiceService } from "@/utils/http";
import ContentLayout from "@/components/layouts/ContentLayout.vue";
import { messageService } from "@/utils";

@Component({
  components: {
    ContentLayout
  },
})
export default class StripePaymentReceivedCallback extends Vue {
  @Prop({ required: true })
  public route: any;
  isLoading = false;
  sessionDetails:any=null;
  redirectUrl= ''


  created() {
  this.synchornizePayments();
  }
  generatePaymentReceipt(){
    messageService.showLoading("Generating Payment Receipt")
    invoiceService.downloadPaymentReceipt(this.sessionDetails.receiptId).then(()=>{
      messageService.closeDialogs();
    }).catch(err=>{
       messageService.showError('Error','Failed to generate receipt')
    })
  }
 
  synchornizePayments() {
    const sessionid=this.$route.query.session_id as string;
    const postRedirectUrl=this.$route.query.origin as string;
    this.redirectUrl=postRedirectUrl;
    this.isLoading=true;
     invoiceService.processStripeSession(sessionid).then((result)=>{
     
     this.sessionDetails=result;
     }).catch(err=>{
       
     }).finally(()=>{
      this.isLoading=false;
      
     })
  }
  redirectToPostProcess(){
    window.location.href=this.redirectUrl;
  }
}
</script>
<style scoped>

</style>