<template>
  <a class="video-icon" role="button"   title="Instruction Video" v-on:click.prevent.stop="showVideo"  >
    <i class="fa-brands fa-youtube text-danger" />

    </a>

</template>
<script lang="ts">
import { defineComponent, toRefs, PropType } from "vue";
import { messageBuss } from "@/utils";
export default defineComponent({
  components: {},
  props: {
    videoId: { default: null, required: true, type: Object as PropType<any> },
  },
  setup(props, ctx) {
    const { videoId } = toRefs(props);
    const showVideo = () => {
      messageBuss.triggerEvent(messageBuss.eventTypes.ShowInstructionVideo, {
        videoId: videoId.value,
      });
    };
    (() => {})();
    return { showVideo };
  },
});
</script>

<style scoped>

</style>