<template>
    <span>{{$store.getters.clientName}}</span>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {
  
  },
})
export default class TopNavUserDetails extends Vue{
    created(){
     
    }
}
</script>