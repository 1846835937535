<template>
  <b-card class="mb-1">
    <b-card-title>
      {{ invoice.invoiceCode }}-{{ invoice.invoiceNumber }}
    </b-card-title>
    <b-card-sub-title>
      {{ invoice.clientName }}
    </b-card-sub-title>
    <div class="amount">
      <h5 class="m-0">
       <b-badge class="mr-1" variant="success" v-if="invoice.status==invoiceStatuses.PaidComplete">PAID</b-badge>
       <b-badge  class="mr-1" variant="warning" v-else-if="invoice.status==invoiceStatuses.PartiallyPaid">{{invoice.unpaidAmount|money}} remaining</b-badge>
       <b-badge  class="mr-1" variant="danger" v-else-if="invoice.status==invoiceStatuses.Canceled">VOIDED</b-badge>
        <b-badge variant="dark"> {{ invoice.totalAmount | money }} </b-badge>
      </h5>
      <h6 class="m-0">
        <b-badge variant="light"
          >{{ invoice.amount | money }} + Tax {{ invoice.tax | money }}</b-badge
        >
      </h6>
    </div>
    <!-- <div v-if="invoice.paragraphs.length>0" class="mt-1 mb-1" style="max-height:100px;overflow:auto;text-alight:left">
         <b-badge style="text-align:left" class="d-block m-1"  :key="index" variant="light"
        v-for="(paragraph, index) in invoice.paragraphs" >{{ paragraph }}</b-badge>
    </div> -->
   
    <b-badge class="card-link">{{ invoice.date | date }}</b-badge>
    <b-link v-if="fileUrl" @click="download" class="card-link"
      >View Document</b-link>
     
   
   
  </b-card>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { fileUtils } from "@/utils";
import ContentLayout from "@/components/layouts/ContentLayout.vue";
import constants from "@/common/enums";
@Component({
  components: {
    ContentLayout,
  },
})
export default class Invoices extends Vue {
  @Prop({ required: true })
  invoice: any;
  invoiceStatuses= constants.invoiceStatuses
@Prop({ required: false,default:'' })
  fileUrl:string

  created() {

  }
  download(){
      if(!this.fileUrl) return;
      fileUtils.downloadFileFromLink(this.fileUrl);
  }

 
}
</script>
<style scoped>
.amount {
  position: absolute;
  top: 5px;
  right: 5px;
  text-align: right;
}
</style>