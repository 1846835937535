<template>

    <div class="container-fluid h-100">
      <div class="row no-gutters h-100">
        <div class="col-12 h-100">
          <ContentLayout>
            <template #header>
             
          
              <b-button-toolbar
                class="d-inline-block m-2"
                
              >
               

                <b-button-group class="mx-1">
                    <b-button title="Refresh" variant="link" @click="loadData">
                    <i class="fa fa-refresh"></i>
                  </b-button>
                  <b-button variant="link" @click="isListMode = !isListMode">
                    <i v-if="isListMode" class="fa fa-th-large"></i>
                    <i v-else class="fa fa-list"></i>
                  </b-button>
                
                  
                </b-button-group>
              </b-button-toolbar>
               <b-alert show variant="warning">
                  Files will be permanenlty removed after 30 days of being deleted
                </b-alert>
            </template>
              <b-skeleton-wrapper :loading="isLoading">
    <template #loading>
      <b-card-group deck>
        <b-card class="skeleton-card" v-for="index in 10" :key="index">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </b-card-group>
    </template>

            <b-card title="Folders" v-if="folders.length > 0">
              <b-card-group class="m-0" deck v-if="!isListMode">
                <div
                  v-for="file in folders"
                  :key="file.id"
                 
                
                >
                  <card-file-item :showSelection="true"
                    @selected="currentSelectedItem = file.id"
                    :file="file"
                  />
                </div>
              </b-card-group>
              <b-list-group v-else>
                <div
                  v-for="file in folders"
                  :key="file.id"
                 
                >
                  <list-file-item :showSelection="true"
                    @selected="currentSelectedItem = file.id"
                    :file="file"
                  />
                </div>
              </b-list-group>
            </b-card>
            <div  style="min-height:200px;width:100%">
            <b-card title="Files" >
              <template v-if="documents.length > 0">
                <b-card-group class="m-0" deck v-if="!isListMode">
                  <div
                    v-for="file in documents"
                    :key="file.id"
                  
                  >
                    <card-file-item style="width:100px"  :showSelection="true" :file="file" />
                  </div>
                </b-card-group>
                <b-list-group v-else>
                  <div
                    v-for="file in documents"
                    :key="file.id"
                  
                  >
                    <list-file-item :showSelection="true" :file="file" />
                  </div>
                </b-list-group>
              </template>
              <template v-else>
                <b-card title="No Files">
                  <b-card-sub-title v-if="filter && filter.hasFilter">
                    Try changing your filters
                  </b-card-sub-title>
                  <b-card-text>
                    You don't seem to have any files in this folder.
                  </b-card-text>
                </b-card>
              </template>
              
            </b-card>
            </div>
              </b-skeleton-wrapper>
             
          </ContentLayout>
        </div>
       
      </div>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { fileService } from "@/utils/http";
import CardFileItem from "./common/CardFileItem.vue";
import ListFileItem from "./common/ListFileItem.vue";
import FolderDetails from "./common/FolderDetails.vue";
import ContentLayout from "@/components/layouts/ContentLayout.vue";
import FilePicker from "@/components/Files/common/FilePicker.vue";
import FileUploader from "@/components/Files/common/FileUploader.vue";
import FolderPath from "@/components/Files/common/FolderPath.vue";
import { messageBuss } from "@/utils/";
import FileFilterComponent from './common/Filter.vue'
import {FileFilter} from '@/common/models'

@Component({
  components: {
    FileFilterComponent,
    FolderPath,
    FilePicker,
    FileUploader,
    ListFileItem,
    CardFileItem,
    FolderDetails,
    ContentLayout
  },
})
export default class Trash extends Vue {
  
  files: any[] = [];
  isLoading = false;
  currentSelectedItem = "";
  isListMode = false;
  showFilter=false;
  filter:FileFilter=null;

  onFilter(filter:FileFilter){
   this.filter=filter;
   this.loadData();
  }


  get folders() {
    return this.files.filter((z) => z.isFolder);
  }
  get documents() {
    return this.files.filter((z) => !z.isFolder);
  }

  created() {
    messageBuss.onEvent(messageBuss.eventTypes.FileRestored, this.loadData);
    messageBuss.onEvent(messageBuss.eventTypes.FilePermanentDeleted, this.loadData);
    messageBuss.onEvent(messageBuss.eventTypes.FirmChanged, this.reset);

    this.loadData();
  }
  beforeDestroy(){
    messageBuss.offEvent(messageBuss.eventTypes.FileRestored, this.loadData);
    messageBuss.offEvent(messageBuss.eventTypes.FilePermanentDeleted, this.loadData);
    messageBuss.offEvent(messageBuss.eventTypes.FirmChanged, this.reset);

  }
  reset(){
    this.files=[];
    this.loadData();
  }

  get areAllSelected() {
    return this.documents.every((z) => z.selected);
  }
  get isAnythingSelected() {
    return this.documents.some((z) => z.selected);
  }
  selectAllOrNone() {
    let shouldSelect = !this.areAllSelected;
    this.documents.forEach((z) => (z.selected = shouldSelect));
  }
  loadData() {
    this.isLoading = true;
    let queryFilter=''
    if(!this.filter){
      queryFilter=new FileFilter().getQueryString();
    }
    else{
      queryFilter=this.filter.getQueryString();
    }
    fileService.getTrashFolderFiles(queryFilter).then((files) => {
      files.forEach((file) => {
        file.selected = false;
      });
      this.files = files;
      this.isLoading = false;
    });
  }
  fileRestored() {
    this.loadData();
  }

  
}
</script>
<style scoped>
.skeleton-card {
  max-width: 200px;
  min-width: 200px;
  margin: 20px;
}

</style>