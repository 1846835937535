import {httpService} from '@/utils/http';
import Resources from '@/common/resources';

class PaymentGatewayService {
 
  
  public getGatewaysForInvoice(token:string): Promise<any> {
    return httpService.get(`${Resources.paymentGateways}/invoices/${token}`);
  }
  public getGatewaysForPrepayment(token:string): Promise<any> {
    return httpService.get(`${Resources.paymentGateways}/prepayment/${token}`);
  }
  
 
 
  
}
export const paymentGatewayService = new PaymentGatewayService();
