<template>
  <div class="container-fluid">
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <b-card class="skeleton-card" v-for="index in 3" :key="index">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>

      <div class="row" v-if="!errorMessage">
        <div class="m-2 col-12">
          <b-card
            title="Deposit money to your account"
            class="p-2"
           
          >
            
              <b-alert v-if="gateways.length == 0" show variant="warning">
                No payment methods configured yet. Contact your firm to get more
                details
              </b-alert>
              <template v-if="hasMultiplePaymentGateway" >
          <b-button v-for="gateway in gateways" :key="gateway.id" variant="link"
            @click="selectGateway(gateway)" class=" m-2">
            <b-img :src="gateway.logo" rounded style="height:40px" class="pay-button" alt="Circle image"></b-img>
            <span class="d-block">
              {{ gateway.name }}
            </span>
          </b-button>
              </template>
             
            <b-card-footer >
              You are depositing on behalf of <b>{{clientDetails.ClientName}}</b>
             
            </b-card-footer>
          </b-card>
         
        </div>
        <div class="m-2 col-12"  v-if="currentPaymentProcessor">
          <b-card>
        <componet :gatewayId="currentPaymentProcessor.id" :clientId="clientDetails.ClientId" :tenantId="clientDetails.TenantId" @paymentreceived="onPaymentRecived" :is="currentPaymentProcessor.component"
      ></componet>
    </b-card>
      </div>
      </div>
      <div v-else>
        <b-alert variant="danger" show>
          {{ errorMessage }}
        </b-alert>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { paymentGatewayService } from "@/utils/http";
import ContentLayout from "@/components/layouts/ContentLayout.vue";
import {jwtUtils} from '@/utils/jwt'
import { linkService } from "@/utils/http";
@Component({
  components: {
    ContentLayout,
    'stripe': () => import('./Stripe/StripePrepayment.vue'),
    'paypal': () => import('./PayPal/PayPalPrePayment.vue'),
    'cpacharge': () => import('./CPACharge/CPAChargePrepayment.vue'),
    'payclix': () => import('./PayClix/PayClixPrepayment.vue'),
    'clover': () => import('./Clover/CloverPrepayment.vue'),
  },
})
export default class PrePaymentGatewayHandler extends Vue {
  @Prop({ required: true,default:false })
  public isAnonymous: boolean;
  @Prop({ required: false })
  public paymentToken: string;
  errorMessage=''
  isLoading = false;
  gateways: any[] = [];
  clientDetails:{ClientName:String,TenantId:String,ClientId:String}=null;

  currentPaymentProcessor: any = null
  get hasPaymentGateway() {
    return this.gateways.length > 0
  }
 get hasMultiplePaymentGateway() {
    return this.gateways.length > 1
  }
  created(){
    this.loadLinkDetails()
  }
  loadLinkDetails(){
    this.isLoading=true;
       linkService.getLink(this.paymentToken).then(result=>{
        this.isLoading=false;
        if(!result.isValid){
         this.errorMessage="Invalid Link"
        }
        else {
          this.clientDetails={ClientName:result.linkData.clientName,TenantId:result.linkData.tenantId,ClientId:result.linkData.clientId};
          this.loadPaymentGateway();
        }
       })

  
       
  }
  mounted() {

   
  }
  selectGateway(gateway) {
    this.currentPaymentProcessor =gateway;
    this.currentPaymentProcessor.component= gateway.url.split('/')[1];
  
  }
  loadPaymentGateway() {
    this.isLoading = true;
    paymentGatewayService.getGatewaysForPrepayment(this.paymentToken).then((data) => {
      this.gateways = data.gateways;
      if(this.gateways.length>0) this.selectGateway(this.gateways[0])
      this.isLoading = false;
    });
  
  }
  onPaymentRecived(){
   this.$emit('paymentreceived')
  }

  beforeDestroy() {
    // this.offEvent(this.constants.eventTypes.PaymentApplied,this.paymentApplied)
  }
}
</script>
<style scoped>
.pay-button {
  max-height: 30px;
}
</style>