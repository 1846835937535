<template>
 <div>
  <b-skeleton-wrapper :loading="isLoading">
    <template #loading>
      <b-card class="skeleton-card" v-for="index in 3" :key="index">
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </b-card>
    </template>
   <b-alert v-if="errorMessage" variant="danger" show >
       {{errorMessage}}
   </b-alert>
  </b-skeleton-wrapper>
 
 </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import {jwtUtils} from '@/utils/jwt'
@Component({
  components: {
  },
})
export default class LinkHandler extends Vue {
  @Prop({ required: true })
  public url: string;
  isLoading:Boolean=true;
  errorMessage:String=''
  created() {
    this.handleInvoiceLink();
   
  }
  
   handleInvoiceLink(){
           this.isLoading=false;

    if(!this.url){
        this.errorMessage="Invalid link"
    }
    else{
      this.url= this.url.replace('/','')
       let decodedToken= jwtUtils.decode(this.url,true);
       if(!decodedToken){
            this.errorMessage="Invalid link"
            return;
       }
       switch(decodedToken.LinkType){
           case "InvoiceShare": return this.navigateToInvoiceShare();
           case "Deposit": return this.navigateToDeposit();
           default: this.errorMessage="Invalid Link";
       }
    }
  }
navigateToInvoiceShare(){
      this.$router.replace(`/external/invoices/${this.url}`)
}
navigateToDeposit(){
      this.$router.replace(`/external/deposits/${this.url}`)

}
  
 
}
</script>
<style scoped>

</style>