<template>

    <div class="container-fluid h-100">
      <div class="row no-gutters h-100">
        <div  class="h-100 col-12">
          <ContentLayout>
            <template #header>
            
               <FileFilterComponent @onFilter="onFilter" /> 
            </template>
              <b-skeleton-wrapper :loading="isLoading">
    <template #loading>
      <b-card-group deck>
        <b-card class="skeleton-card" v-for="index in 10" :key="index">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </b-card-group>
    </template>

            <b-table hover :fields="fields" :items="visibleFiles">
              
      <template #cell(isReceived)="data">
        <b-badge v-if="data.item.isReceived" variant="primary">
          Added by firm
        </b-badge>
        <b-badge v-else variant="success">
          {{data.item.isSelf?'Added by you':'Added by portal member'}}
        </b-badge>
       
      </template>
      <template #cell(createdOn)="data">
        {{ data.item.createdOn|localdate}}
      </template>localdate
       <template #cell(parentFolderName)="data">
       <router-link v-if="data.item.parentFolderId" :to="{name:'library',params:{folderId:data.item.parentFolderId}}">
        {{data.item.parentFolderName}}
       </router-link>
       <template v-else>
         {{data.item.parentFolderName}}
       </template>
      </template>
      <template #cell(actions)="data">
        <b-button v-if="data.item.permisisons && data.item.permisisons.canDownload"  title="Download" size="sm" variant="link" class="d-inline" @click="download(data.item.id)">
          <i class="fa fa-download"></i>
        </b-button>
        <b-button title="Delete" v-if="data.item.deleteable && data.item.permisisons && data.item.permisisons.canDelete" size="sm" class="d-inline" variant="link" @click="deleteFile(data.item.id)">
          <i  class="fa fa-trash text-danger"></i>
         
        </b-button>
      </template>

            </b-table>
              </b-skeleton-wrapper>
              <template #footer>
                <b-card class="m-0">
                   {{visibleFiles.length}} Files
                </b-card>
              </template>
          </ContentLayout>
        </div>
      
      </div>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { fileService } from "@/utils/http";
import ContentLayout from "@/components/layouts/ContentLayout.vue";
import { messageBuss, messageService } from "@/utils";
import FileFilterComponent from './common/Filter.vue'
import {FileFilter} from '@/common/models'
import { fileUtils } from "@/utils";
@Component({
  components: {
    FileFilterComponent,
    ContentLayout,
  },
})
export default class Library extends Vue {
  @Prop({ required: false, default: "" })
  files: any[] = [];
  isLoading = false;
  showFilter=false;
  filter:FileFilter=null;
     fields:any[]= [
          {
            key: 'name',
            sortable: true
          },
          {
            label:'ShareMode',
            key: 'isReceived',
            sortable: true
          },
          {
            key: 'createdOn',
            label: 'Share Date',
            sortable: true,
          },
           {
             label: 'Folder',
            key: 'parentFolderName',
            sortable: true
          },
          {
             label: '',
            key: 'actions',
            sortable: false
          },
          
        ]
  
 onFilter(filter:FileFilter){
  
   this.filter=filter;

  }

  filterFunc(filter:FileFilter){
    if(!filter) return (item)=>true;
    return (item)=>{
      if(filter.search && item.name.toLowerCase().indexOf(filter.search.toLowerCase())===-1){
        return false;
      }
      if(filter.shareMode==2 && item.isReceived){ //if filter is sent but item is received don't show
        return false;
      }
      if(filter.shareMode==1 && !item.isReceived){ 
        return false;
      }
      if(filter.ext && filter.ext!=item.ext){
        return false;
      }
      return true;
    }
  }

  get visibleFiles() {
   let filterPredicate=this.filterFunc(this.filter);
    return this.files.filter(filterPredicate);
  }
  created() {
    messageBuss.onEvent(messageBuss.eventTypes.FirmChanged, this.reset);

    this.loadFiles();
  }
  beforeDestroy(){
    messageBuss.offEvent(messageBuss.eventTypes.FirmChanged, this.reset);

  }
  reset(){
    this.files=[];
    this.loadFiles();
  }
  download(id) {
    fileService.getDownloadLink(id).then((link) => {
     fileUtils.downloadFileFromLink(link)
    });
  }
 async deleteFile(id){
    var confirmation= await messageService.confirm("Confirmation","Are you sure you want to delete this file? You can find the file in the trash folder for up to 30 days after deleting it.","Yes, delete it")
   if(!confirmation) return;
  await fileService.deleteFile(id)
  this.files.splice(this.files.findIndex(x=>x.id==id),1)
  }
  loadFiles(){
     this.isLoading = true;
    
    if(!this.filter) this.filter=new FileFilter();
    this.filter.showAllFolderIfNoneSpecified=true;
   
    let queryFilter =this.filter.getQueryString();
    

    fileService.getfiles(queryFilter).then((files) => {
     
      this.files = files;
      this.isLoading = false;
    });
  }
 
  
}
</script>
<style scoped>
.skeleton-card {
  max-width: 200px;
  min-width: 200px;
  margin: 20px;
}

.drag-over  {
  border: solid 2px #87cb16!important;
}
.dropable {
}
</style>