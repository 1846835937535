<template>
      <InvoicePaymentGatewayHandler @paymentApplied="onPaymentApplied" v-bind="$props"/>

</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import InvoicePaymentGatewayHandler from '@/components/Payments/InvoicePaymentGatewayHandler.vue'
@Component({
  components: {
    InvoicePaymentGatewayHandler
  },
})
export default class InvoicePaymentsPage extends Vue{
  @Prop({ required: true })
  public invoiceToken: string;
  onPaymentApplied(){
    this.$router.push({name:'invoices'});
  }
}
</script>