<template>
<div class="container-fluid h-100">
  Logging you out....
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {
  
  },
})
export default class Logout extends Vue{
   
    async logout() {
         //@ts-ignore
     this.$msal.signOut();
      
     
    }
    created(){
        this.logout();
    }
}
</script>
<style lang="scss" scoped>

</style>

