import jwt_decode from "jwt-decode";

class JWTUtils {
    
  decode(token:string,isBase64=false):any{
    try {
      if (isBase64) {
        // Use atob to decode base64 string in a browser environment
        token = atob(token);
      }
      const decoded = jwt_decode(token);
      return decoded;
    } catch (err) {
      console.log('decoding failed', err);
      return null;
    }
  }
}
export const jwtUtils =new JWTUtils()