<template>
  <div class="wrapper">
    <div class="no-sidebar-main-panel">
      <ContentLayout>
        <template #header>
          <nav class="navbar navbar-expand-lg">
            <div class="container-fluid">
              <a class="navbar-brand">
                <!-- {{this.$route.name}} -->
              <img style="max-height:30px" src="static/img/TPS Logo-notagline-4c.png" alt="TPS logo">
              </a>

              <div class="collapse navbar-collapse justify-content-end">
                <ul class="nav navbar-nav mr-auto"></ul>
                <ul class="navbar-nav">
                  <!-- <router-link :to="{name:'login'}">Login</router-link> -->
                </ul>
              </div>
            </div>
          </nav>
        </template>
        
        <router-view ></router-view>

        </ContentLayout
      >
    </div>
  </div>
</template>
    
<script>
import ContentLayout from "@/components/layouts/ContentLayout.vue";

export default {
  components: {
    ContentLayout,
  },
  created(){
    console.log('eexternal user layout calleed')
  },
  methods: {},
};
</script>
<style scoped>
.no-sidebar-main-panel{
  background: rgba(203, 203, 210, 0.15);
    position: relative;
    float: right;
    width: 100%;
    height:100%
}
</style>
