<template>
  <balloon :showCloseWhenMaximized="true" @close="videoId=''" @open="opened" v-if="videoId"
      ref="ballon"
      position = 'top-right'
    >
     
<youtube @ended="ended" :player-vars="{start: 0, autoplay: 1,rel:0}" style="height:100%" player-width="100%" player-height="100%"  :video-id='videoId' ref = 'player'>
      </youtube>
    </balloon>
</template>
<script lang="ts">
import { defineComponent, ref,nextTick } from "vue";
import { Balloon } from "vue-balloon";
import { messageBuss } from "@/utils";
export default defineComponent({
  components: {
    Balloon,
  },
  props: {},
  setup(_props, ctx) {
    const videoId = ref("");
    const ballon = ref(null);
    const ended = () => {
      ballon.value?.close();
    };
    const opened = () => {};
    const showVideo = (data) => {
      videoId.value = data.videoId;
      nextTick(() => {
        ballon.value?.maximize();
      });
    };
    (() => {
      messageBuss.onEvent(
        messageBuss.eventTypes.ShowInstructionVideo,
        showVideo
      );
    })();
    return {ballon, videoId, ended, opened, showVideo };
  },
});
</script>

<style scoped>

</style>