<template>
    <b-input-group  size="sm">
    <b-input-group-prepend>
      <b-button @click="reset" v-if="filter.hasFilter" class="mr-1" variant="danger"><i class="fa fa-eraser"></i></b-button>
    </b-input-group-prepend>

    <b-form-input class="mr-1"  size="sm" placeholder="Search files...."  @input="textChanged" type="text" v-model="filter.search"></b-form-input>
   <b-dropdown class="mr-1" :variant="filter.ext?'danger':'link'"  size="sm" :class="{'has-value':filter.ext}"  :text="filter.ext?filter.ext:'Type'">
    <b-dropdown-item  @click="filter.ext='';filterChanged()" >All Types</b-dropdown-item>
    <b-dropdown-item @click="filter.ext=ext;filterChanged()" v-for="ext in extensions" :key="ext">{{ext}}</b-dropdown-item>
  
  </b-dropdown>
     <b-dropdown size="sm"  class="mr-1" :variant="filter.shareMode?'danger':'link'"  :text="filter.shareMode?(filter.shareMode==2?'Sent':'Received'):'Sent/Received'">
    <b-dropdown-item  @click="filter.shareMode=null;filterChanged()" >Sent and Received</b-dropdown-item>
    <b-dropdown-item  @click="filter.shareMode=2;filterChanged()" >Sent</b-dropdown-item>
    <b-dropdown-item  @click="filter.shareMode=1;filterChanged()" >Received</b-dropdown-item>
  
  </b-dropdown>
    <!-- <b-input-group-append>
      <b-button variant="outline-secondary">Button</b-button>
      <b-button variant="outline-primary">Button</b-button>
      <b-input-group-text>
        <b-icon icon="x" />
      </b-input-group-text>
    </b-input-group-append> -->
  </b-input-group>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { fileService } from "@/utils/http";
import { debounce } from "@/utils";
import {FileFilter} from '@/common/models'
import { messageBuss } from "@/utils";

@Component({
  components: {
  },
})
export default class FileFilterComponent extends Vue {
  extensions:string[]=[]
  filter:FileFilter=new FileFilter();
  constructor(){
    super()
        this.textChanged= debounce.debounce(this.textChanged,500);

  }
  created(){
    messageBuss.onEvent(messageBuss.eventTypes.FileUploaded, this.loadExtensions);
     this.loadExtensions();
  }
  loadExtensions(){
 fileService.getAllExtensions().then(result=>{
        this.extensions=result;
    })
  }
  textChanged(){
    this.filterChanged();
  }
  filterChanged(){
    this.$emit('onFilter',this.filter)
  }
  reset(){
    this.filter.reset()
    this.$emit('onFilter',this.filter)
  }
   beforeDestroy(){
    messageBuss.offEvent(messageBuss.eventTypes.FileUploaded, this.loadExtensions);
  }
}
</script>

<style scoped>
.has-value{
  /* border:solid 2px darkkhaki; */
}
</style>