<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar
      :logo="
        'https://eu.ui-avatars.com/api/?rounded=true&size=400&background=random&name=' +
        $store.getters.firmName
      "
      :title="$store.getters.firmName"
    >
      <user-menu :title="$store.getters.currentUser.name"></user-menu>
      <ClientPickerMenu/>
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item :link="{ name: 'Files', icon: 'fa fa-folder-open' }">
          <sidebar-item
            :link="{ name: 'Recent', icon: 'fa-history', path: '/app/recent' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Library', icon: 'fa-folder', path: '/app/library' }"
          ></sidebar-item>
           <sidebar-item
            :link="{ name: 'List', icon: 'fa-list', path: '/app/list' }"
          ></sidebar-item>
              <sidebar-item
            :link="{ name: 'Trash',  icon: 'fa-trash', path: '/app/trash' }"
          ></sidebar-item>
        </sidebar-item>
         <sidebar-item
            :link="{ name: 'Invoices', icon: 'fa-file', path: '/app/invoices' }"
          ></sidebar-item>
           <sidebar-item
            :link="{ name: 'Deposit', icon: 'fa-solid fa-hand-holding-dollar', path: '/app/deposit' }"
          ></sidebar-item>

      </template>
    </side-bar>
    <div class="main-panel">
      <ContentLayout>
        <template #header>
          <top-navbar></top-navbar>
        </template>
        <dashboard-content @click.native="toggleSidebar"> </dashboard-content>
        <template #footer>
          <FileUploader style="max-height:30vh" />
         </template
      ></ContentLayout>
    </div>
  </div>
</template>
    
<script>
import TopNavbar from "./TopNavbar.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import ClientPickerMenu from "./Extra/ClientPickerMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import ContentLayout from "@/components/layouts/ContentLayout.vue";
import FileUploader from "@/components/Files/common/FileUploader.vue";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    ClientPickerMenu,
    FileUploader,
    TopNavbar,
    DashboardContent,
    MobileMenu,
    UserMenu,
    ContentLayout,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("main-panel");
        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
  },
  mounted() {
    // this.initScrollbar()
  },
};
</script>
