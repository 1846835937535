<template>
   <PaymentReceivedCallback :route="route"></PaymentReceivedCallback>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PaymentReceivedCallback from '@/components/Payments/PayClix/PaymentReceivedCallback.vue'
import { Route } from 'vue-router';
@Component({
  components: {
    PaymentReceivedCallback
  },
})
export default class AuthenticatedPayClixPaymentCompletedCallback extends Vue{

  route:any=null;
 created(){
 this.route=this.determineRedirectRoute();
 console.log(this.route);
 
 }

 determineRedirectRoute(){
  let isAnonymous=this.$route.query.isAnonymous;
  let invoiceToken=this.$route.query.invoiceToken;
  let isDeposit=this.$route.query.isDeposit;
  if(invoiceToken){
    return isAnonymous? {name:'externalinvoices',params:{invoiceToken:invoiceToken}}:{name:'invoices'};
  }
  if(isDeposit){
    return isAnonymous? {name:'anonymousdepositreceived'}:{name:'depositreceived'};

  }
 }

}
</script>