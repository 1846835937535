<template>
    <b-skeleton-wrapper :loading="isLoading">
             <template #loading>
                <b-skeleton width="85%"></b-skeleton>
                   <b-skeleton width="55%"></b-skeleton>
                   <b-skeleton width="70%"></b-skeleton>
             </template>
             <b-form @submit="onSubmit"  v-if="file">
             
      <b-form-group
        id="name-group"
        label="Name"
        label-for="name"
        description="Enter a file name without the extension"
      >
      <b-input-group  >
        <b-form-input
          id="name"
          v-model="newName"
          type="text"
          placeholder="Enter name"
          required
        ></b-form-input>
        <b-input-group-append>
       <b-button size="sm" >.{{ file.ext }}</b-button>
    </b-input-group-append>
        </b-input-group>
      </b-form-group>
    
   
   
      <b-button :disabled="!newName || file.name==newName" type="submit" class="mt-2 mr-2" variant="success">Save Changes</b-button>
      <b-button  variant="link" class="mt-2" @click="$emit('cancel')">
                    Cancel
                </b-button>
    </b-form>
            
           </b-skeleton-wrapper>
  </template>
  <script lang="ts">
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { fileService } from "@/utils/http";

  
  @Component({
    components: {
    
    },
  })
  export default class EditFile extends Vue {
    @Prop({ required: false })
    fileId: "";
    isLoading=false;
    file:any=null;
    newName:String=''

    created() {
        this.loadFile();
    }

    onSubmit(event) {
        event.preventDefault();
      this.isLoading=true;
          fileService.updateFile(this.file.id,{name:this.newName}).then(result=>{
            this.$emit('updated')
            this.isLoading=false;
          }).catch(err=>{
            this.isLoading=false;
          })

      }
     
    async loadFile() {
      this.isLoading = true;
      this.file=await fileService.getFile(this.fileId);
      this.file.name=this.file.name.replace(/\.[^/.]+$/, "");//remove extension from the filename
       this.newName=this.file.name;
         this.isLoading = false;
        
     }
  
  }
  </script>
  