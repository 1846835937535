<template>
 <div  class="masthead">
       <div class="container px-4 px-lg-5 h-100">
                <div class="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                    <div class="col-lg-8 align-self-end">
                        <h1 class="text-white font-weight-bold">CLOUD-BASED ACCOUNTING PRACTICE MANAGEMENT SOFTWARE</h1>
                        <hr class="divider" />
                    </div>
                    <div class="col-lg-8 align-self-baseline">
                        <p class="text-light mb-5">TPS Cloud Axis, a cloud-based practice management tool designed exclusively for accountants.</p>
                        <router-link class="btn btn-light btn-lg" to="/login">Login to get started</router-link>
                    </div>
                </div>
            </div>
   </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {
  
  },
})
export default class LoggedOut extends Vue{
   
  
}
</script>
<style scoped>

.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 4.5rem);
  background: linear-gradient(to bottom, rgba(94, 127, 139, 0.8) 0%, rgba(94, 127, 139,0.8) 100%), url("~@/assets/bg-masthead.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
.masthead h1, .masthead .h1 {
  font-size: 2.25rem;
}
@media (min-width: 992px) {
  .masthead {
    height: 100vh;
    min-height: 40rem;
    padding-top: 4.5rem;
    padding-bottom: 0;
  }
  .masthead p {
    font-size: 1.15rem;
  }
  .masthead h1, .masthead .h1 {
    font-size: 3rem;
  }
}
@media (min-width: 1200px) {
  .masthead h1, .masthead .h1 {
    font-size: 3.5rem;
  }
}
</style>

