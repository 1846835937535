<template>
  <b-skeleton-wrapper :loading="isLoading">
    <template #loading>
      <b-breadcrumb>
        <b-breadcrumb-item :key="index" v-for="index in 3">
          <b-skeleton class="d-inline" width="50px"></b-skeleton>
        </b-breadcrumb-item>
      </b-breadcrumb>
    </template>
    <b-breadcrumb v-if="breadCrumbs.length > 0">
      <router-link
        :to="{ name: 'library', params: {} }"
        custom
        v-slot="{ href, navigate }"
      >
        <b-breadcrumb-item
          @dragover.prevent
          @dragenter.prevent="handleDragEnter"
          @dragleave="handleDragLeave"
          @drop="onDrop($event, '')"
          @click.stop="navigate"
          :href="href"
        >
          <i class="fa fa-home"></i>
        </b-breadcrumb-item>
      </router-link>

      <router-link
        :key="folder.id"
        v-for="(folder, index) in breadCrumbs"
        :to="{ name: 'library', params: { folderId: folder.id } }"
        custom
        v-slot="{ href, navigate }"
      >
        <b-breadcrumb-item
          v-if="index != breadCrumbs.length - 1"
          @dragover.prevent
          @dragenter.prevent="handleDragEnter"
          @dragleave="handleDragLeave"
          @drop="onDrop($event, folder.id)"
          @click.stop="navigate"
          :href="href"
          >{{ folder.name | trim(20) }}</b-breadcrumb-item
        >
        <b-breadcrumb-item
          :href="href"
          :disabled="true"
          style="color: #9a9a9a !important"
          v-else
          >{{ folder.name | trim(20) }}</b-breadcrumb-item
        >
      </router-link>
    </b-breadcrumb>
  </b-skeleton-wrapper>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { fileService } from "@/utils/http";
import { messageBuss } from "@/utils";

@Component({
  components: {},
})
export default class FolderPath extends Vue {
  @Prop({ required: true })
  folderId: "";
  isLoading = false;
  folderPath: any = null;
  get breadCrumbs() {
    if (!this.folderPath) return [];
    let result = [];
    result.push({ id: this.folderPath.id, name: this.folderPath.name });
    let currentPath = this.folderPath.parent;
    while (currentPath != null) {
      result.unshift({ id: currentPath.id, name: currentPath.name });
      currentPath = currentPath.parent;
    }
    return result;
  }
  created() {
    this.loadFolderPath();
  }
  @Watch("folderId")
  loadFolderPath() {
    if (!this.folderId) {
      this.folderPath = null;
    } else {
      this.isLoading = true;
      fileService.getFolderPath(this.folderId).then((path) => {
        this.folderPath = path;
        this.isLoading = false;
      });
    }
  }
  addClassToEvent(evt: DragEvent, className: string) {
    //@ts-ignore
    evt.srcElement.classList.add(className);
  }
  removeClassFromEvent(evt: DragEvent, className: string) {
    //@ts-ignore
    evt.srcElement.classList.remove(className);
  }
  handleDragEnter(evt: DragEvent) {

    this.addClassToEvent(evt, "drag-over");
  }

  handleDragLeave(evt: DragEvent) {
    this.removeClassFromEvent(evt, "drag-over");
  }
  onDrop(evt, folderId) {
    this.removeClassFromEvent(evt, "drag-over");
    const dragData = evt.dataTransfer.getData("tps-drag-file-ids");
    evt.dataTransfer.setData("tps-drag-file-ids", "");
    if (!dragData) return;
    let fileIds = dragData.split(",");
    fileService.setParent(fileIds, folderId).then(() => {
      messageBuss.triggerEvent(
        messageBuss.eventTypes.FilesMoved, {fileIds:fileIds,folderId:folderId}
      );
    });
  }
}
</script>

<style scoped>
.drag-over {
 font-size: larger;
 color:white;
 background: lightslategray;
 border-radius: 5px;
}
.file-item {
  max-width: 200px;
  min-width: 200px;
  margin: 5px;
}
.folder-item {
  max-width: 200px;
  min-width: 200px;
  margin: 5px;
}
.folder-icon {
  top: 5px;
  right: 5px;
  position: absolute;
}
</style>