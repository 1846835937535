<template>
  <b-card class="mb-1 p-0" :class="{'selected-invoice':invoice.selected}">
    <b-card-title>
      <b-form-checkbox class="d-inline-block" v-if="canBePaid" v-model="invoice.selected"
    
    >
    </b-form-checkbox>
 {{ invoice.invoiceCode }}-{{ invoice.invoiceNumber }}
    </b-card-title>
    <b-card-sub-title>
      {{ invoice.clientName }} 
    </b-card-sub-title>
    <b-badge class="card-link">{{ invoice.date | date }}</b-badge>
    <div class="amount">
      <h5 class="m-0">
        <b-badge
          class="mr-1"
          variant="success"
          v-if="invoice.status == invoiceStatuses.PaidComplete"
          >PAID</b-badge
        >
        <b-badge
          class="mr-1"
          variant="warning"
          v-else-if="invoice.status == invoiceStatuses.PartiallyPaid"
          >{{ invoice.unpaidAmount | money }} remaining</b-badge
        >
        <b-badge
          class="mr-1"
          variant="danger"
          v-else-if="invoice.status == invoiceStatuses.Canceled"
          >VOIDED</b-badge
        >
        <b-badge variant="dark"> {{ invoice.totalAmount | money }} </b-badge>
      </h5>
      <h6 class="m-0">
        <b-badge variant="light"
          >{{ invoice.amount | money }} + Tax {{ invoice.tax | money }}</b-badge
        >
      </h6>
    </div>
  
   
    
    <div class="row no-gutters">
       <div class="col-12">
        <b-link v-if="invoice.fileId" @click="loadAndShowFile" class="card-link"
      >View Document</b-link
    >
    <b-link v-if="invoice.paidAmount>0" :disabled="isGeneratingReceipt"  @click="generateReceipt" class="card-link"
      >
      
      <b-spinner v-if="isGeneratingReceipt" label="Generating"></b-spinner>
      Generate Receipt
      
      </b-link
    >
          <b-button v-if="canBePaid && !hidePay"
        class="float-right"
        variant="success"
        size="sm"
        @click="$emit('pay', invoice.invoiceId)"
      >
        Pay 
      </b-button>
       </div>
      
     </div>
    <b-modal v-if="file" hide-footer size="xl" v-model="showFile">
      <FileView
        :controlsAlwaysVisible="true"
        style="max-width: unset"
        :showNavigateToFolder="true"
        :file="file"
      />
    </b-modal>
  </b-card>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { fileService, invoiceService } from "@/utils/http";
import ContentLayout from "@/components/layouts/ContentLayout.vue";
import FileView from "@/components/Files/common/CardFileItem.vue";
import constants from "@/common/enums";
@Component({
  components: {
    ContentLayout,
    FileView,
  },
})
export default class Invoices extends Vue {
  @Prop({ required: true })
  invoice: any;
  @Prop({ required: false,default:false })
  hidePay: boolean;
  showFile = false;
  file:any=null;
  isGeneratingReceipt=false;
  invoiceStatuses= constants.invoiceStatuses
  created() {}

  get canBePaid() {
    return (
      this.invoice.unpaidAmount > 0 &&
      (this.invoice.status == constants.invoiceStatuses.Unpaid ||
        this.invoice.status == constants.invoiceStatuses.PartiallyPaid)
    );
  }
  loadAndShowFile(){
    fileService.getFile(this.invoice.fileId).then(result=>{
      this.file=result;
      this.showFile=true;
    })
  }
  generateReceipt(){
    this.isGeneratingReceipt=true;
     invoiceService.downloadInvoicePaymentReceipt(this.invoice.invoiceId).then(result=>{
     this.isGeneratingReceipt=false;
    }).catch(err=>{
      this.isGeneratingReceipt=false;
    })
  }
}
</script>
<style scoped>
.amount {
  position: absolute;
  top: 5px;
  right: 5px;
  text-align: right;
}
.pay-button {
  max-height: 30px;
}
.selected-invoice{
  border-left:10px solid green;
}
</style>