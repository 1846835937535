import { render, staticRenderFns } from "./MyFiles.vue?vue&type=template&id=07bdede3"
import script from "./MyFiles.vue?vue&type=script&lang=ts"
export * from "./MyFiles.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports