<template>
  <b-skeleton-wrapper :loading="isLoading">
    <template #loading>
      <b-card-group deck>
        <b-card class="skeleton-card" v-for="index in 10" :key="index">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </b-card-group>
    </template>

    <div class="container-fluid h-100">
      <div class="row no-gutters h-100">
        <div  class="col-12 h-100">
          <ContentLayout>
           <template #header>
             Recent Files 
             <router-link :to="{name:'library'}">Go to library</router-link>
           </template>
           
            <b-card title="Files" >
              <template v-if="documents.length > 0">
                <b-card-group class="m-0" deck v-if="!isListMode">
                 
                    <card-file-item :showNavigateToFolder="true"   v-for="file in documents"
                    :key="file.id" :file="file" />
                  
                </b-card-group>
                <b-list-group v-else>
                 
                    <list-file-item :showNavigateToFolder="true"   v-for="file in documents"
                    :key="file.id" :file="file" />
                
                </b-list-group>
              </template>
              <template v-else>
                <b-card title="No Files">
                  <b-card-text>
                    You don't seem to have any recent files shared with or by your.
                  </b-card-text>
                  <router-link :to="{name:'library'}">Go to library</router-link>
                </b-card>
              </template>
              
            </b-card>
          
          </ContentLayout>
        </div>
     
      </div>
    </div>
  </b-skeleton-wrapper>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { fileService } from "@/utils/http";
import CardFileItem from "./common/CardFileItem.vue";
import ListFileItem from "./common/ListFileItem.vue";
import FolderDetails from "./common/FolderDetails.vue";
import ContentLayout from "@/components/layouts/ContentLayout.vue";
import FilePicker from "@/components/Files/common/FilePicker.vue";
import FileUploader from "@/components/Files/common/FileUploader.vue";
import FolderPath from "@/components/Files/common/FolderPath.vue";
import { messageBuss } from "@/utils/";
import FileFilter from './common/Filter.vue'
@Component({
  components: {
    FileFilter,
    FolderPath,
    FilePicker,
    FileUploader,
    ListFileItem,
    CardFileItem,
    FolderDetails,
    ContentLayout
  },
})
export default class RescentFiles extends Vue {
  files: any[] = [];
  isLoading = false;
  isListMode = false;
 
 

  get documents() {
    return this.files.filter((z) => !z.isFolder);
  }


  created() {
    messageBuss.onEvent(messageBuss.eventTypes.FileUploaded, this.loadData);
    messageBuss.onEvent(messageBuss.eventTypes.FirmChanged, this.reset);

    this.loadData();
  }
  beforeDestroy(){
    messageBuss.offEvent(messageBuss.eventTypes.FileUploaded, this.loadData);
    messageBuss.offEvent(messageBuss.eventTypes.FirmChanged, this.reset);
  }
  
 
  loadData() {
    this.isLoading = true;
    fileService.getRescentFiles().then((files) => {
      this.files=files
      this.isLoading = false;
    });
  }
  reset(){
    this.files=[]
    this.loadData()
  }
}
</script>
<style scoped>
.skeleton-card {
  max-width: 200px;
  min-width: 200px;
  margin: 20px;
}

</style>