import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import 'bootstrap/dist/css/bootstrap.css'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'animate.css';
import vSelect from 'vue-select'
import VueYouTubeEmbed from "vue-youtube-embed";
Vue.use(VueYouTubeEmbed);
import HelpVideo from './components/common/help/HelpVideo.vue'
Vue.component('help-clip', HelpVideo);

Vue.component('v-select', vSelect);
import LightBootstrap from './light-bootstrap-main'
Vue.use(LightBootstrap)
Vue.use(BootstrapVue);

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
import { ToastPlugin } from 'bootstrap-vue'
Vue.use(ToastPlugin)

import VueContentPlaceholders from 'vue-content-placeholders'
import EventHub from 'vue-event-hub';
// import VueCarousel from 'vue-carousel';
// Vue.use(VueCarousel);
import filters from './filters'
filters.initialize();
Vue.use(EventHub);
Vue.use(VueContentPlaceholders)
Vue.config.productionTip = false;


import { MsalPlugin } from './plugins/msal-plugin';
const options = {
  clientId: "82f47d69-9c16-4844-8def-37d7a3d1ec09",
  loginAuthority: "https://devapptpssoftware.b2clogin.com/devapptpssoftware.onmicrosoft.com/",
  defaultUserFlow: "B2C_1_signin",
  knownAuthority :"devapptpssoftware.b2clogin.com",
  redirectUri:  document.location.protocol + `//` + document.location.host.replace('www.','') + "/accountpicker",
  postLogoutRedirectUri: document.location.protocol + `//` + document.location.host.replace('www.','') + "/loggedout",
  scopes:[ "openid", "profile", "https://devapptpssoftware.onmicrosoft.com/82f47d69-9c16-4844-8def-37d7a3d1ec09/tpsapps" ]
};
Vue.use(new MsalPlugin(), options);

new Vue({
  router,
  store,
  render: (h) => h(App),
  async created () {
    Vue.prototype.$toast=this.$bvToast;
  }
}).$mount('#app');