<template>
 <div>
  <b-skeleton-wrapper :loading="isLoading">
    <template #loading>
      <b-card class="skeleton-card" v-for="index in 3" :key="index">
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </b-card>
    </template>
   <b-alert v-if="errorMessage" variant="danger" show >
       {{errorMessage}}
   </b-alert>
  </b-skeleton-wrapper>
 
 </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import {jwtUtils} from '@/utils/jwt'
import { linkService } from "@/utils/http";
import { PortalLinkType } from "@/common/models";
@Component({
  components: {
  },
})
export default class ShareLinkHandler extends Vue {
  @Prop({ required: true })
  public code: string;
  isLoading:Boolean=true;
  errorMessage:String=''
  created() {
    this.handleInvoiceLink();
   
  }
  
   handleInvoiceLink(){
           this.isLoading=false;

    if(!this.code){
        this.errorMessage="Invalid link"
    }
    else{
      this.code= this.code.replace('/','')
      linkService.getLink(this.code).then(result=>{
       if(!result.isValid){
        this.errorMessage="Invalid Link"
       }
       else{
        if(result.linkData.type==PortalLinkType.InvoicePayment)return this.navigateToInvoiceShare();
        else if(result.linkData.type==PortalLinkType.PaymentDeposit) return this.navigateToDeposit();
        else{
          this.errorMessage="Link type not supported"
        }
       }
      })
      
    }
  }
navigateToInvoiceShare(){
      this.$router.replace(`/external/invoices/${this.code}`)
}
navigateToDeposit(){
      this.$router.replace(`/external/deposits/${this.code}`)

}
  
 
}
</script>
<style scoped>

</style>