<template>
  <b-card
  :bg-variant="file.selected?'info':'light'"
  :text-variant="file.selected?'white':''"
    v-if="!file.isFolder"
    class="file-item" no-body @click="file.selected=!file.selected"
  >
  
   <b-card-body class="m-0 p-0">
  
    
      <template v-if="file.canPreview && !file.thumbnailGenerationFailed">
    <image-zoom
    :zoom-amount="4"
    :show-message="false"
    :show-message-touch="false"
      img-class="img-thumbnail m-0 border-0 p-0 img-fluid"
      v-if="file.thumbnailUrl"
      :regular="file.thumbnailUrl"
    >
    </image-zoom>
      
      <div v-else class="skeleton-card img-thumbnail border-0" >
        <br/>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
              <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
              <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
              <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </div>
    </template>
    <template v-else >
    <img :src="getImageForExtension(file.ext)" class="img-thumbnail no-preview-img"/>
    </template>
<div class="share-mode-badge">
      <b-badge variant="primary" v-if="file.isReceived">Added by firm</b-badge>
      <b-badge v-else variant="success">{{file.isSelf?'Added by you':'Added by portal member'}}</b-badge>
    </div>
   
   </b-card-body>
   <b-card-footer class="p-1">
      <h6 :title="file.name" class="fileName">{{file.name}}</h6>
     
      <router-link :class="{'hover-show':!controlsAlwaysVisible}" @click.stop @click.prevent v-if="!file.isDeleted && file.folderId && showNavigateToFolder" :to="{name:'library', params:{folderId:file.folderId}}" > Go to folder</router-link>
      <small><b-badge variant="link">{{ file.createdOn |localdatetime }}</b-badge></small>

    <b-button class="mr-1" size="sm" :disabled="!canDownload" :class="{'hover-show':!controlsAlwaysVisible}"   @click.stop="download" ><i class="fa fa-download"></i> Download</b-button>
    <b-button class="mr-1" size="sm" :class="{'hover-show':!controlsAlwaysVisible}" v-if="canEdit"   @click.stop="$emit('edit',file.id)" ><i class="fa fa-edit"></i> Edit</b-button>
    <b-button class="mr-1" size="sm" :disabled="!canDelete" v-if="file.deleteable && !file.isDeleted" variant="danger" :class="{'hover-show':!controlsAlwaysVisible}"  @click.stop="deleteFile" ><i class="fa fa-trash"></i> Delete</b-button>
    <b-button class="mr-1" size="sm" v-if="file.isDeleted" variant="success" :class="{'hover-show':!controlsAlwaysVisible}"  @click.stop="restoreFile" ><i class="fa fa-undo"></i> Restore</b-button>
    <b-button class="mr-1" size="sm" v-if="file.isDeleted" variant="danger" :class="{'hover-show':!controlsAlwaysVisible}"  @click.stop="permanentDelete" ><i class="fa fa-trash"></i> Delete Permanently</b-button>
     <b-badge class="m-1" variant="danger" v-if="file.deletedOn">Deleted {{ file.deletedOn | localdatetime }}</b-badge>

   </b-card-footer>
  </b-card>
  <router-link
    v-else
    custom
    :to="{ name: 'library', params: { folderId: file.id } }"
    v-slot="{ href, navigate }"
  >
    <div
      class="folder-item card "
      style="background: white"
      :class="{ 'border-primary': file.selected }"
     @click="navigate"
    >
      
      <div   class="card-body mt-2 ml-2" style="pointer-events: none">
        <h6 class="card-subtitle mb-2 text-muted">{{ file.name }}</h6>
        <small class="folder-date"><b-badge variant="link">{{ file.createdOn |localdatetime }}</b-badge></small>
        <i class="fa fa-folder-open folder-icon" :class="{'text-success':file.permisisons.canUpload}" ></i>
      </div>
       <b-dropdown text="..." style="position:absolute;bottom:0;right:0" v-if="!file.isFirmFixed"
                variant="link"
                no-caret
              >
            <template #button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                
                  <b-dropdown-item @click.stop="renameFolder"
                  >Rename
                </b-dropdown-item>
                <b-dropdown-item @click.stop="deleteFolder" variant="danger"
                  >Delete
                </b-dropdown-item>
                
              </b-dropdown>
    </div>
  </router-link>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import imageZoom from "vue-image-zoomer";
import FileItem from './FileItem.vue'

@Component({
  components: {
    imageZoom,
  },
})
export default class FileItemCard extends FileItem {
  @Prop({ default: false, required: false })
  controlsAlwaysVisible: boolean;

 
  created(){
    this.tryLoadThumbnail();
  }
}
</script>

<style scoped>
.select-checkbox{
  visibility: visible;
  position:absolute;
  top:1px;
  left:1px
}
.select-checkbox > .form-check-input{
 opacity: 1;
 visibility: visible;
}
.file-item {
  max-width: 300px;
  min-width: 200px;
  margin: 5px;
}
.file-item .card-body .card-subtitle {
  margin-top: 10px;
}
.folder-item {
  max-width: 200px;
  min-width: 200px;
  margin: 5px;
  /* pointer-events:calc(); */
}
.folder-icon {
  top: 5px;
  left: 5px;
  position: absolute;
  color:#f8d775
}
/* .folder-date{
  position: absolute;
  top: 5px;
  right: 5px;
} */

.share-mode-badge {
  position: absolute;
  top: 0px;
  right: 2px;
}
.no-preview-img{
  border:none;
  opacity: 0.5;
  padding:50px
}
.selected{
  border: solid #28a745 5px !important;
}
.hover-show{
  display: none;
}
.file-item:hover .hover-show{
  display: unset;;
}
.fileName{
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */

}
</style>