<template>
  <div class="user" v-if="firms.length>0">
    <div class="photo">
      <img  :src="'https://eu.ui-avatars.com/api/?rounded=true&size=400&background=random&name=' +
        $store.getters.firmName" alt="avatar"/>
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click.stop="toggleMenu">
           <span>
             {{$store.getters.firmName}}
             <b class="caret"></b>
          </span>
      </a>
      <div class="clearfix"></div>
      <div>
          <template  >
            <ul class="nav firm-nav" v-show="!isClosed">
            
            <li v-if="firms.length>10">
              <a class="profile-dropdown "><input type="text" @input="filterChanged" v-model="firmSearchTerm" class="form-control form-control-sm" placeholder="Search by firm name or client name"/>
            </a>
            </li>
            <li v-for="firm in filteredFirms" >
                <a role="button" @click="changeFirm(firm)" class="profile-dropdown firm-item"   >
                  <span class="sidebar-normal " :title="firm.displayName"> {{firm.displayName |trim(15)}} - <small :title="firm.clientName">{{ firm.clientName }}</small>
                   
                  </span>
                 
                 
                </a>
              </li>
             
          </ul>
          </template>
         
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { accountService } from "../../../utils/http";
import { Component, Prop, Vue } from "vue-property-decorator";
import { debounce } from "@/utils";
import store from "@/store";
import { messageBuss } from "@/utils";

@Component({
  components: {
  },
})
export default class AccountPicker extends Vue {
  isClosed= true;
  firms = [];
  filteredFirms=[];
  isLoading = false;
  firmSearchTerm='';
  constructor(){
    super()
        this.filterChanged= debounce.debounce(this.filterChanged,500);

  }
  async created() {
   
    this.loadFirms();
   
  }
  toggleMenu () {
        this.isClosed = !this.isClosed
      }
  filterChanged(){
    if(!this.firms) this.filteredFirms=[];
    if(!this.firmSearchTerm)this.filteredFirms=[...this.firms];
    let searchTerm=this.firmSearchTerm.toLowerCase();
    this.filteredFirms=this.firms.filter(z=>(z.clientName && z.clientName.toLowerCase().includes(searchTerm)) || z.displayName.toLowerCase().includes(searchTerm));
  }
  changeFirm(firm) {
    this.$store.commit("setCurrentFirmAndClient", {
      firmId: firm.id,
      firmName: firm.displayName,
      clientName: firm.clientName,
      clientId: firm.clientId,
    });
    messageBuss.triggerEvent(messageBuss.eventTypes.FirmChanged);
  }
 
  get firmsWithPortal(){
    if(!this.firms) return [];
    return this.firms.filter(z=>z.hasPortal)
  }
  async loadFirms() {
    this.isLoading=true;
    accountService
      .getFirmsWithAccess()
      .then((result) => {
        this.firms = result.sort(this.firmSortFunction).filter(z=>z.hasPortal);
        this.filteredFirms=this.firms
        store.commit('firms',this.firmsWithPortal);
        this.isLoading = false;
      })
      .catch((err) =>{
        this.isLoading = false;
        console.log(err);
      });
  }

  
firmSortFunction(a, b) {          
      if (a.displayName === b.displayName) {
         // Price is only important when cities are the same
         return a.clientName > b.clientName ? 1 : -1;
      }
      return a.displayName > b.displayName ? 1 : -1;
   }
  
}
</script>
<style>
  .collapsed {
    transition: opacity 1s;
  }
  .firm-nav{
    max-height:200px;
    overflow:auto;
    
  }
  .firm-item{
    white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
        padding: 0!important;
  }
  .firm-item .sidebar-normal{
    white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
        
  }
</style>

