
      class InvoiceStatus
      {
         Unpaid = 41
         PaidComplete = 42
         PartiallyPaid = 43
         Canceled = 218
      }
   class EventTypes
      {
          QueueFileUpload='QueueFileUpload'
          FileUploaded='FileUploaded'
          FileDeleted='FileDeleted'
          FilePermanentDeleted='FilePermanentDeleted'
          FolderDeleted='FolderDeleted'
          FolderUpdated='FolderUpdated'
          FileRestored='FileRestored'
          FilesMoved='FilesMoved'
          PaymentApplied='PaymentApplied'
          ShowInstructionVideo="ShowInstructionVideo"
          FirmChanged="FirmChanged"
      }
    
   class Constants {
    eventTypes= new EventTypes();
    invoiceStatuses= new InvoiceStatus();
  }
  
  const constants= new Constants()
  export default  constants;
  