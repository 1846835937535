<template>
<b-card v-if="files.length>0" class="h-100 m-0 p-0 pr-1 pl-1 mr-1 ml-1" no-body bg-variant="dark" text-variant="light" >
  <ContentLayout>
  <template #header>
  <div class="w-100  position-relative" @click="visible=!visible" >
      Uploading {{files.length}} files <b-button class="stretched-link m-0 p-0" style="float:right" size="sm" variant="light" >
      <i v-if="visible" class="fa fa-angle-down"></i>
      <i v-else class="fa fa-angle-up"></i>
      </b-button>
      <b-progress  class="mb-0" :max="files.length * 100" >
      <b-progress-bar :key="file.fileId"  v-for="file in files" :value="file.progress">
        
      </b-progress-bar>
    </b-progress>
  </div>
  </template>
  <b-collapse v-model="visible" class="m-0">
    
      <b-card class="w-100 p-0 m-0" bg-variant="dark" text-variant="light"
        v-for="file in files"
        :key="file.fileId"
        :sub-title="file.name"
      >
      
          <b-progress   class="mb-1 " :max="100" height="1rem">
            <b-progress-bar :value="file.progress">
              <span @click="file.progress=5"
                >Progress: <strong>{{ file.progress }}%</strong></span
              >
            </b-progress-bar>
          </b-progress>
      </b-card>
    
  </b-collapse>
</ContentLayout>
</b-card>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { BlockBlobClient } from "@azure/storage-blob";
import ContentLayout from "@/components/layouts/ContentLayout.vue";

import { fileService } from "@/utils/http";
import { messageBuss, messageService } from "@/utils";
@Component({
  components: {
    ContentLayout
  },
})
export default class FileUploader extends Vue {
  files: {
    name: string;
    progress: string | number;
    file: File;
    link: string;
    fileId: string;
  }[] = [];
  visible = true;
  mounted() {}
  async startUpload(fileDetails: {
    name: string;
    progress: string | number;
    file: File;
    link: string;
    fileId: string;
  }) {
    const blobClient = new BlockBlobClient(fileDetails.link);

    blobClient
      .uploadData(fileDetails.file, {
        onProgress: (progress) => {
          var percentCompleted = (
            (progress.loadedBytes / fileDetails.file.size) *
            100
          ).toFixed(0);
          fileDetails.progress = percentCompleted;
        },
      })
      .then(() => {
        fileService.completeFileUpload(fileDetails.fileId).then((result:any) => {
          if(result.automaticallyMoved){
            messageService.showToastInfo(`File ${fileDetails.name} was uploaded and moved automatically to folder: ${result.automaticallyMovedToFolderName} because of policies set by your firm`)
          }
          messageBuss.triggerEvent(messageBuss.eventTypes.FileUploaded, null);
          this.files.splice(
            this.files.findIndex((f) => f.fileId == fileDetails.fileId),
            1
          );
        });
      });

    // console.log("uploadedfile", uploadedFile);
    // this.isUploading = false;
    // this.uploadFinished = true;
    // this.$emit("uploaded");
  }

  created() {
    // this.files.push({
    //   name: "Document.jpg",
    //   progress: 50,
    //   file: null,
    //   link: "",
    //   fileId: "1",
    // });
    //   this.files.push({
    //   name: "Document2.jpg",
    //   progress: 50,
    //   file: null,
    //   link: "",
    //   fileId: "2",
    // });
    //      this.files.push({
    //   name: "Document3.jpg",
    //   progress: 50,
    //   file: null,
    //   link: "",
    //   fileId: "3",
    // });
    //      this.files.push({
    //   name: "Document4.jpg",
    //   progress: 50,
    //   file: null,
    //   link: "",
    //   fileId: "4",
    // });
    messageBuss.onEvent(
      messageBuss.eventTypes.QueueFileUpload,
      this.queueFileForUpload
    );
  }
  beforeDestroy() {
    messageBuss.offEvent(
      messageBuss.eventTypes.QueueFileUpload,
      this.queueFileForUpload
    );
  }
  async queueFileForUpload(data,b) {
    var files = data.files;
    console.log('file queued for uploaded',data)
    console.log('file queued for uploaded b',b)

    var folderId = data.folderId;
    files.forEach(async (file) => {
      var fileItem = {
        name: file.name,
        progress: 0,
        file: file,
        link: "",
        fileId: "",
      };
      var uploadData = await fileService.getFileUploadLink(file.name, folderId);
      fileItem.link = uploadData.link;
      fileItem.fileId = uploadData.fileId;
      this.startUpload(fileItem);
      this.files.push(fileItem);
    });
    console.log("filequeued for upload", data);
  }
}
</script>