import Vue from 'vue';
import Vuex from 'vuex';
import VuexReset from '@ianwalter/vuex-reset'

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [VuexReset()],
  state: {
    currentUser:{},
   isInitialized:false,
   currentFirmId: null,
   currentClientId: null,
   isLoading: false,
   currentFirmName:'',
   currentClientName:'',
   firms:[]
  },
  getters: {
    firms(state){
      return state.firms
    },
    firmName(state){
      return state.currentFirmName
    },
    clientName(state){
      return state.currentClientName
    },
    isInitialized (state) {
      return state.isInitialized
    },
    currentFirmId (state) {
      return state.currentFirmId;
    },
    currentClientId (state) {
      return state.currentClientId;
    },
    isLoading (state) {
      return state.isLoading;
    },
    
    currentUser(state){
      return state.currentUser
    }
  },
  mutations: {
    reset: () => {
      
    },
    isLoading (state,loading) {
      state.isLoading =loading;
    },
    firms(state, firms:any[]){
      state.firms=firms
    },
    setCurrentFirmAndClient (state,data:{firmId:string,firmName:string, clientId:string,clientName:string}) {
      state.currentFirmId=data?.firmId;
      state.currentFirmName=data?.firmName;
      state.currentClientName=data?.clientName;
      state.currentClientId=data?.clientId
    },
    setCurrentUser(state,user) {
      state.currentUser=user;
    },
    initialiseStore(state) {
      if (localStorage.getItem('tps_manager_store')) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('tps_manager_store')))
        );
      }
      state.isInitialized = true;
      state.isLoading=false; // ignore state for this varaible
    },
  },
  actions: {

  },
});

// Subscribe to store updates
store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem('tps_manager_store', JSON.stringify(state));
});

export default store;