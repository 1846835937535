import {httpService} from '@/utils/http';
import Resources from '@/common/resources';

class FileService {
 
  public canDeleteFolder(folderId:String): Promise<boolean> {
    return httpService.get(`${Resources.folders}/candelete/${folderId}`);
  }
  public deleteFolder(folderId:String): Promise<any> {
    return httpService.delete(`${Resources.folders}/${folderId}`,{});
  }
  public updateFolder(folderId:String, data:{name:String}): Promise<any> {
    return httpService.put(`${Resources.folders}/${folderId}`,data);
  }
  public deleteFile(fileId:String,isPermanent:Boolean=false): Promise<any[]> {
    return httpService.delete(`${Resources.files}/${fileId}?isPermanent=${isPermanent||false}`,{});
  }
  public restoreFile(fileId:String): Promise<any[]> {
    return httpService.post(`${Resources.files}/restore`,[fileId]);
  }
  public getfiles(filterQuery:string=null): Promise<any[]> {
    return httpService.get(`${Resources.files}?${filterQuery||''}`);
  }
  public getFolders(parentId:string=null): Promise<any[]> {
    return httpService.get(`${Resources.folders}?parentId=${parentId||''}`);
  }
  public getTrashFolderFiles(filterQuery:string=null): Promise<any[]> {
    return httpService.get(`${Resources.files}/trash?${filterQuery||''}`);
  }
  public getRescentFiles(): Promise<any[]> {
    return httpService.get(Resources.rescent_files);
  }
  public getFile(id:string): Promise<any> {
    return httpService.get(`${Resources.files}/${id}`);
  }
  public updateFile(id:string,data:any): Promise<any> {
    return httpService.put(`${Resources.files}/${id}`,data);
  }
  public getFileUploadLink(name: string,folderId:String): Promise<{link:string,fileId:string}> {
    return httpService.post(Resources.fileuploadlink,{name:name,folderId:folderId||''});
  }
  public completeFileUpload(id: string): Promise<{link:string,fileId:string}> {
    return httpService.post(`${Resources.completefileupload}/${id}`,{});
  }
  public getFolderPath(id: string): Promise<any> {
    return httpService.get(`${Resources.folderpath}/${id}`);
  }
  public getDownloadLink(id: string): Promise<any> {
    return httpService.get(`${Resources.dowloadLink}/${id}`);
  }
  public getDownloadLinks(ids: string[]): Promise<any> {
    return httpService.post(Resources.dowloadLinks,ids);
  }
  public getThumbnailLink(id: string): Promise<any> {
    return httpService.get(`${Resources.thumbnaillink}/${id}`);
  }
  public createFolder(name: string, folderId:string): Promise<any> {
    return httpService.post(Resources.folders,{name:name,folderId:folderId||''});
  }
  public setParent(documents: string[], parentId:string): Promise<any> {
    return httpService.post(Resources.setfoldeorfileparent,{documents:documents,parentId:parentId||''});
  }
  public getAllExtensions(): Promise<string[]> {
    return httpService.get(Resources.extensions);
  }
  
}
export let fileService = new FileService();
