import Vue from 'vue';
import Router from 'vue-router';

import Login from '../components/login/Login.vue';
import AccountPicker from '../components/login/AccountPicker.vue';
import Logout from '../components/logout/Logout.vue';
import LoggedOut from '../components/logout/LoggedOut.vue';
import DashboardLayout from '../views/layout/DashboardLayout.vue';
import ExternalUserLayout from '../views/layout/ExternalUserLayout.vue';
import LibraryPage from '../views/files/MyFiles.vue';
import FileListPage from '../views/files/FileListPage.vue';
import TrashPage from '../views/trash/TrashFolder.vue';
import InvoicesPage from '../views/invoices/MyInvoices.vue';
import AuthenticatedInvoicePaymentsPage from '../views/payment/AuthenticatedInvoicePayment.vue';
import AnonymousAuthenticatedInvoicePaymentPage from '../views/payment/AnonymousInvoicePayment.vue';

import AuthenticatedPrepaymentPage from '../views/payment/AuthetnicatedPrepaymentPage.vue';
import AnonymousPrepaymentPage from '../views/payment/AnonymousPrepaymentPage.vue';
import AuthenticatedDepositReceived from '../views/payment/AuthenticatedDepositReceived.vue';
import AnonymousDepositReceived from '../views/payment/AnonymousDepositReceived.vue';
import AuthenticatedPayClixPaymentCompletedCallback from '../views/payment/PayClixPaymentCompletedCallback.vue';
import PaymentReceivedCallback from '../views/payment/PaymentReceivedCallback.vue';
import RescentFilesPage from '../views/files/RescentFiles.vue';

import LinkHandler from '@/components/link/LinkHandler.vue'
import ShareLinkHandler from '@/components/link/ShareLinkHandler.vue'
import store from '../store';
import { msalPluginInstance } from "@/plugins/msal-plugin";

Vue.use(Router);
function isIE() {
  const ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  const is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

  return is_ie;
}
const router=  new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/app/library'
    },
     {
       meta:{
        requiresAuth:true,
       },
      path:'/app',
      component:DashboardLayout,
      children:[
        {
          name:'library',
          path: 'library/:folderId?',
          component: LibraryPage,
          props:true
        },
        {
          name:'list',
          path: 'list',
          component: FileListPage,
          props:true
        },
        {
          name:'trash',
          path: 'trash',
          component: TrashPage,
          props:true
        },
        
        {
          name:'recent',
          path: 'recent',
          component: RescentFilesPage,
          props:true
        }
        ,
        {
          name:'deposit',
          path: 'deposit',
          component:AuthenticatedPrepaymentPage,
          props:true
        },
        {
          name:'depositreceived',
          path: 'depositreceived',
          component:AuthenticatedDepositReceived,
          props:true
        },
        {
          name:'invoices',
          path: 'invoices',
          component: InvoicesPage,
          props:true
        },
        {
          name:'invoicepayment',
          path: 'invoicepayment/:invoiceToken',
          component: AuthenticatedInvoicePaymentsPage,
          props:true
        }
       
      ]
     },
     {
      meta:{
       requiresAuth:false,
      },
     path:'/external',
     component:ExternalUserLayout,
     children:[
       {
         name:'externalinvoices',
         path: 'invoices/:invoiceToken',
         component: AnonymousAuthenticatedInvoicePaymentPage,
         props:true
       },
       {
        name:'externaldeposit',
        path: 'deposits/:paymentToken',
        component: AnonymousPrepaymentPage,
        props:true
      },
      {
        name:'anonymousdepositreceived',
        path: 'depositreceived',
        component:AnonymousDepositReceived,
        props:true
      },
     ]
    },
   { 
     path:'/link',
    component:LinkHandler,
    props:  (route) => ({ ...route.params, ...route.query }) ,
    meta:{
      requiresAuth:false,
     },
  },
  { 
    path:'/sharelink',
   component:ShareLinkHandler,
   props:  (route) => ({ ...route.params, ...route.query }) ,
   meta:{
     requiresAuth:false,
    },
 },
  
  { 
    path:'/paymentreceived',
   component:PaymentReceivedCallback,
   props:  (route) => ({ ...route.params, ...route.query }) ,
   meta:{
     requiresAuth:false,
    },
 },
  {
    path: '/payclixpaymentreceived',
    props: { sidenav: (route) => ({ ...route.params, ...route.query }) },
    component:AuthenticatedPayClixPaymentCompletedCallback
  },
    {
      path: '/login',
      name:'login',
      component: Login,
    },
    {
      path: '/logout',
      component: Logout,
    },
    {
      path: '/accountpicker',
      component: AccountPicker,
      name:'accountpicker'
    },
    {
      path: '/loggedout',
      component: LoggedOut,
    }
  ],
});
router.beforeEach(function(to, from, next) {
  if (isIE() && !to.matched.some((z) => z.path === '/message')) {
    next({
      path: 'message',
      query: {
        title: 'Browser not supported',
        message: 'We are sorry, but we do not support this browser',
      },
    });
    return;
  }
  if (!store.getters.isInitialized) {
    store.commit('initialiseStore');
  }
  if (!to.matched.some((z) => z.meta.requiresAuth)) { next(); } else {
    if (msalPluginInstance.isAuthenticated) { next(); } else { next({path: '/login'}); }
  }

});
export default router;
