<template>
    <MyFiles v-bind="$props"/>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import MyFiles from '@/components/Files/Library.vue'
@Component({
  components: {
  MyFiles
  },
})
export default class MyFilesPage extends Vue{
   @Prop({required:false})
   folderId:''
  
}
</script>