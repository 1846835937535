import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosPromise } from 'axios';
import Resources from '../../common/resources'
import store from '@/store'
import router from '@/router'
import { messageService } from '../message.service'
import { msalPluginInstance } from "@/plugins/msal-plugin";

class HttpService {
  private apiClient: AxiosInstance;
  private authTokenRequest: any;
  constructor(withInterceptor = false) {
    this.apiClient = axios.create();
    if (withInterceptor) {
      this.apiClient.interceptors.response.use(undefined, (err) => {
        const error = err.response;
        if(error.data && error.data.errorCode===410){
        
          msalPluginInstance.reinitialize(error.data.data.newUserFlowId)
          msalPluginInstance.signIn()
         
          return Promise.reject(err)
        }
        else if (error.data && error.data.errorMessage) {
          messageService.showToastError(error.data.errorMessage)

        }
        return  Promise.reject(err);
      });
    }
  }

  private async getConfig(): Promise<AxiosRequestConfig> {
    return {
      headers: {
        Authorization: await this.getAuthorizationHeaderValue()
      },
    }
  }

  private async getAuthorizationHeaderValue(): Promise<string> {
    return `Bearer ${await msalPluginInstance.acquireToken()}`
  };

  private ResponsePipe<T>(response: AxiosPromise<any>): Promise<T> {
    return new Promise((resolve, reject) => {
      response.then((result: AxiosResponse<T>) => {
        resolve(result.data)
      }).catch((error) => {
        reject(error);
      });
    });
  }
  public async get<T>(url: string): Promise<T> {
    let response =  this.apiClient.get(this.prepareUrl(url), await this.getConfig())
    return this.ResponsePipe<T>(response);
  }
 
  public async post<T>(url: string, data: any): Promise<T> {
    let response=this.apiClient.post(this.prepareUrl(url), data, await this.getConfig());
    return this.ResponsePipe<T>(response);;
  }
  public async put<T>(url: string, data: any): Promise<T> {
    let response = this.apiClient.put(this.prepareUrl(url), data, await this.getConfig())
    return this.ResponsePipe<T>(response);
  }
  public async patch<T>(url: string, data: any): Promise<T> {
    let response = this.apiClient.patch(this.prepareUrl(url), data, await this.getConfig())
    return this.ResponsePipe<T>(response);
  }
  public async delete<T>(url: string, data: any): Promise<T> {
    let response = this.apiClient.delete(this.prepareUrl(url), await this.getConfig());
    return this.ResponsePipe<T>(response);
  }
   //no auth
   public getWithNoAuth<T> (url): Promise<T> { 
   
    let response =  this.apiClient.get(this.prepareUrl(url))
    return this.ResponsePipe<T>(response);
  }
  
  public async downloadFile(url:string):Promise<void>{
    const config= await this.getConfig();
    return this.downloadFileInternal(url,config);

  }
  public async downloadWithoutAuth(url:string):Promise<void>{
    return this.downloadFileInternal(url,null);

  }

  public async postWithNoAuth<T>(url: string, data: any): Promise<T> {
    let response=this.apiClient.post(this.prepareUrl(url), data);
    return this.ResponsePipe<T>(response);;
  }
  private async downloadFileInternal(url: string, config:AxiosRequestConfig):Promise<void> {
    try {
  
      if(!config) config={ responseType: 'blob'}
      else config.responseType='blob'
      // Make the API call
      const response = await axios.get<Blob>(this.prepareUrl(url), config);
  
      // Extract the filename from the response headers
      const contentDisposition = response.headers['content-disposition'];
      const filename = contentDisposition
        ? contentDisposition.split(';')[1].split('filename=')[1].trim()
        : 'Invoice.pdf';
  
      // Create a temporary download link
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob([response.data]));
      downloadLink.setAttribute('download', filename);
  
      // Trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error('Failed to download file:', error);
    }
  }
  private prepareUrl(url){
    return url
    .replace('{tenantId}',store.getters.currentFirmId||'00000000-0000-0000-0000-000000000000')
    .replace('{clientId}',store.getters.currentClientId||'00000000-0000-0000-0000-000000000000');
  }

 
}
export const httpService = new HttpService(true);
export const httpServiceWithoutInterceptor = new HttpService();
export enum RequestMethods {
  get = 1,
  post = 2,
  put = 3,
  delete = 4,
  patch = 5,
}
