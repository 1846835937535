<template>
  <div>
    <HelpVideoContainer/>
    <router-view/>
  </div>
  
  </template>
  
  <script lang="ts">
  import HelpVideoContainer from './components/common/help/HelpVideoContainer.vue'
  import { Component, Vue } from 'vue-property-decorator';
  @Component({
    components:{
      HelpVideoContainer
    }
  })
  export default class App extends Vue {
  constructor() {
      super();  
  }
  
  }
  </script>
  <style  lang="scss">
  @import 'assets/css/scrollbar.css';
  </style>
  
  
  