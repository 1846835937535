<template>
    <Invoices v-bind="$props"/>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Invoices from '@/components/Invoices/Invoices.vue'
@Component({
  components: {
  Invoices
  },
})
export default class InvoicesPage extends Vue{
   
  
}
</script>