<template>
   <div class="container-fluid">

  <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <b-card class="skeleton-card" v-for="index in 3" :key="index">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>
  <div class="row">

    <div class="col-12 mt-2">
      <template v-if="invoiceDetails.length==1 || !canBePaid">
      <InvoiceDetails v-for="invoice in invoiceDetails" :invoice="invoice" :fileUrl="invoice.fileUrl" />
    </template>
    <template v-else>
      <b-card >
    <b-card-title>
      {{ amountToPay| money }}
    </b-card-title>
    <b-card-sub-title>
     {{ invoiceDetails.length }} invoices <b-button variant="link" @click="showAllInvoices = true"> View Invoices </b-button>
    </b-card-sub-title>
   <b-modal lazy title="Invoices" size="lg" v-model="showAllInvoices" hide-footer>
    <InvoiceDetails v-for="invoice in invoiceDetails" :invoice="invoice" :fileUrl="invoice.fileUrl" />

  </b-modal>
   
  </b-card>
    </template>
   

    </div>
    
    <div class="col-12"  >
      <template v-if="canBePaid">
        <b-tabs v-if="canBePaid && hasMultiplePaymentGateway">
    <b-tab  lazy  v-for="gateway in paymentGateways" :key="gateway.id">
      <template #title >
        <b-img :src="gateway.logo" rounded style="height:20px" class="pay-button" alt="Circle image"></b-img>
            <span class="d-block">
              {{ gateway.name }}
            </span>
      </template>
      <componet :gatewayId="gateway.id" :isAnonymous="isAnonymous" @paymentreceived="onPaymentRecived" :is="gateway.key"
      :invoiceToken="invoiceToken"></componet>
    </b-tab>
  </b-tabs>
  <componet :gatewayId="defaultPaymentGateway.id" v-else-if="canBePaid && hasPaymentGateway" :isAnonymous="isAnonymous" @paymentreceived="onPaymentRecived" :is="defaultPaymentGateway.key"
      :invoiceToken="invoiceToken"></componet>
      </template>
      <b-alert show variant="warning" v-else-if="canBePaid && !hasPaymentGateway">
           No payment methods configured yet. Contact your invoice issuer to get more details
           </b-alert> 

    
    </div>
  </div>
</b-skeleton-wrapper>
</div>


</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { invoiceService,paymentGatewayService } from "@/utils/http";
import ContentLayout from "@/components/layouts/ContentLayout.vue";
import InvoiceDetails from '@/components/Payments/common/InvoiceDetails.vue'

@Component({
  components: {
    ContentLayout,
    InvoiceDetails,
    'stripe': () => import('./Stripe/StripeInvoicePayment.vue'),
    'paypal': () => import('./PayPal/PayPalInvoicePayment.vue'),
    'cpacharge': () => import('./CPACharge/CPAChargeInvoicePayment.vue'),
    'payclix': () => import('./PayClix/PayClixInvoicePayment.vue'),
    'clover': () => import('./Clover/CloverInvoicePayment.vue'),
  },
})
export default class InvoicePaymentGatewayHandler extends Vue {
  @Prop({ required: true,default:false })
  public isAnonymous: boolean;
  @Prop({ required: true })
  public invoiceToken: string;
  showAllInvoices=false;
  isLoading = false;
  currentPaymentProcessor: string = null

  invoiceDetails: any[] = null;
  paymentGateways: any[] = []
 
  get amountToPay() {
    return this.invoiceDetails.reduce((a, b) => a + b.unpaidAmount, 0);
  }
  get showSideBySide() {
    return false;
    return this.invoiceDetails && this.invoiceDetails.length > 1
  }
  get canBePaid() {
    return this.invoiceDetails && this.invoiceDetails.some(z=>z.unpaidAmount>0);
 
  }
  get defaultPaymentGateway() {
    if(this.paymentGateways.length==0) return
    return this.paymentGateways[0]
  }
  get hasPaymentGateway() {
    return this.paymentGateways.length > 0
  }
 get hasMultiplePaymentGateway() {
    return this.paymentGateways.length > 1
  }
  async created() {

    await this.loadData();
    console.log('invoice payment handler loaded')

  }
  async loadData(){
    await this.loadInvoices();
    await this.loadPaymentGateways();
  }
  async loadInvoices() {
    this.isLoading = true;
    this.invoiceDetails =await invoiceService
      .getPublicInvoiceDetails(this.invoiceToken);
      this.isLoading = false;
  }
  async loadPaymentGateways() {
    this.isLoading = true;
    let gateWays=await paymentGatewayService
      .getGatewaysForInvoice(this.invoiceToken);
  gateWays.forEach(gateway => {
    gateway.key=gateway.url.split('/')[1]
  })
    this.paymentGateways=gateWays;
      this.isLoading = false;
  }
  onPaymentRecived() {
    this.loadData();
    this.$emit('paymentreceived')

  }
  selectGateway(gatewayUrl) {
    this.currentPaymentProcessor = gatewayUrl.split('/')[1];
  
  }

  beforeDestroy() {
  }

}
</script>
<style scoped>

</style>