<template>
<div class="container-fluid h-100">
   
    <div class="d-flex justify-content-center align-items-center h-100">
      <b-card
       
        :img-src="require('@/assets/TPS Logo-notagline-4c.png')"
        img-alt="Image"
        img-top
      >
       
<hr>
        <b-button variant="primary" @click="login">Login</b-button>
      </b-card>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {
  
  },
})
export default class Login extends Vue{
   
    async login() {
         //@ts-ignore
     await  this.$msal.signIn();
      
     
    }
 async mounted() {
     //@ts-ignore
    if( this.$msal.isAuthenticated){
     this.$router.push('accountpicker')
    }
    else {
      this.login();
    }
  }
}
</script>
<style lang="scss" scoped>

</style>

