import {httpService} from '@/utils/http';
import Resources from '@/common/resources';

class InvoiceService {
 
  
  public getInvoices(): Promise<any[]> {
    return httpService.get(`${Resources.invoices}`);
  }
  public getInvoiceToken(invoiceId:string): Promise<string> {
    return httpService.post(`${Resources.invoices}/generateinvoicetoken`,[invoiceId]);
  }
  public getInvoicesToken(invoices:string[]): Promise<string> {
    return httpService.post(`${Resources.invoices}/generateinvoicetoken`,invoices);
  }
  public getClientPrepaymentToken(): Promise<string> {
    return httpService.get(`${Resources.client}/prepaymenttoken`);
  }
 
 

  public downloadPaymentReceipt(intentId:string): Promise<any> {
    return httpService.downloadWithoutAuth(`${Resources.publicpayments}/receipt/${intentId}`);
  }
  public downloadInvoicePaymentReceipt(invoiceId:string): Promise<any> {
    return httpService.downloadFile(`${Resources.invoices}/${invoiceId}/receipt`);
  }
  public getPublicInvoiceDetails(token:string): Promise<any> {
    return httpService.getWithNoAuth(`${Resources.publicinvoices}/invoicedetails/${token}`);
  }

  public cpaChargePay(intentId:string,credentials:any): Promise<any> {
    return httpService.postWithNoAuth(`${Resources.cpaChargePayment}/pay/${intentId}`,credentials);
  }
    public cloverPay(intentId:string,cardInfo:any): Promise<any> {
    return httpService.postWithNoAuth(`${Resources.cloverConnectPayment}/pay/${intentId}`,cardInfo);
  }
  public stripePay(intentId:string,cardInfo:any): Promise<any> {
    return httpService.postWithNoAuth(`${Resources.stripePayment}/pay/${intentId}`,cardInfo);
  }
  public createStripeSession(sessionDetails:any): Promise<any> {
    return httpService.postWithNoAuth(`${Resources.stripePayment}/session`,sessionDetails);
  }
  public processStripeSession(sessionId:string): Promise<any> {
    return httpService.postWithNoAuth(`${Resources.stripePayment}/process/${sessionId}`,{});
  }
  public getPayClixLink(intentId:string,metadata): Promise<any> {
    return httpService.postWithNoAuth(`${Resources.payClixPayment}/link/${intentId}`,metadata);
  }
 
  public synchornizePayclix(): Promise<any> {
    return httpService.postWithNoAuth(`${Resources.payClixPayment}/processpayments`,{});
  }
  

  public getStripeGatewayDetails(gatewayId:string,invoiceToken:string=null, tenantId:string=null): Promise<boolean> {
    return httpService.getWithNoAuth(`${Resources.stripePayment}/gateway/${gatewayId}?invoiceToken=${invoiceToken||''}&tenantId=${tenantId||''}`);
  }
  public isPayClixGatewayValid(gatewayId:string,invoiceToken:string=null, tenantId:string=null): Promise<boolean> {
    return httpService.getWithNoAuth(`${Resources.payClixPayment}/isgatewayvalid/${gatewayId}?invoiceToken=${invoiceToken||''}&tenantId=${tenantId||''}`);
  }
  public cpaChargeGatewayDetails(gatewayId:string,invoiceToken:string=null, tenantId:string=null): Promise<any> {
    return httpService.getWithNoAuth(`${Resources.cpaChargePayment}/gateway/${gatewayId}?invoiceToken=${invoiceToken||''}&tenantId=${tenantId||''}`);
  }
  public getPayPalGatewayDetails(gatewayId:string,invoiceToken:string=null, tenantId:string=null): Promise<{currency,isValid,clientId}> {
    return httpService.getWithNoAuth(`${Resources.payPalPayment}/gateway/${gatewayId}?invoiceToken=${invoiceToken||''}&tenantId=${tenantId||''}`);
  }
   public getCloverGatewayDetails(gatewayId:string,invoiceToken:string=null, tenantId:string=null): Promise<{currency,isValid,site,hasECheck}> {
    return httpService.getWithNoAuth(`${Resources.cloverConnectPayment}/gateway/${gatewayId}?invoiceToken=${invoiceToken||''}&tenantId=${tenantId||''}`);
  }

  
 
  
}
export const invoiceService = new InvoiceService();
