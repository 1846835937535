<template>
    <div class="layout-box">
        <div class="layout-header hide-empty">
            <slot name="header">
            </slot>
        </div>
        <div class="layout-content hide-empty">
            <slot>
            </slot>
        </div>
        <div class="layout-footer hide-empty">
            <slot name="footer">
            </slot>
        </div>
    </div>
    
</template>
<script>

  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
    function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
       console.log('scrollbar initiated')
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }
export default {
    methods:{
        initScrollbar() {
        let docClasses = document.body.classList;
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
         
          // if we are on windows OS we activate the perfectScrollbar function
          initScrollbar('layout-content');

          docClasses.add('perfect-scrollbar-on');
        } else {
          docClasses.add('perfect-scrollbar-off');
        }
      }
    },
    mounted(){
      // this.initScrollbar()
    }
}
</script>
<style scoped>
.layout-box {
  width:100%;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.layout-box .layout-header {
  flex: 0 1 auto;
}
.layout-box .layout-content {
  flex: 1 1 auto;
  overflow: auto;
}
.layout-box .layout-footer {
  flex: 0 1 auto;
  
}
</style>