import {httpService} from '@/utils/http';
import {PrepayInputModel} from '../../common/models'
import Resources from '@/common/resources';

class PaymentIntentService {
 
  

  
  public createInvoicePaymentIntent(gatewayId, token:string): Promise<any> {
    return httpService.postWithNoAuth(`${Resources.paymentIntent}/invoice/${gatewayId}/${token}`,{});

  }
  public createPrePaymentIntent(gatewayId:string,prepayment:PrepayInputModel): Promise<any> {
    return httpService.postWithNoAuth(`${Resources.paymentIntent}/payment/${gatewayId}`,prepayment);

  }
  
 
  
}
export const paymentIntentService = new PaymentIntentService();
