export class FileFilter{
    public search:String='';
    public shareMode:number=null;
    public fromDate:Date=null;
    public toDate:Date=null;
    public ext:string=null;
    public showAllFolderIfNoneSpecified:Boolean=false;
    public getQueryString(folderId=''):string{
        let result=''
        if(this.search) result+=`search=${this.search}&`
        if(this.shareMode) result+=`shareMode=${this.shareMode}&`
        if(this.fromDate) result+=`fromDate=${this.fromDate}&`
        if(this.toDate) result+=`fromDate=${this.toDate}&`
        if(this.ext) result+=`ext=${this.ext}&`
        if(folderId) result+=`folderId=${folderId}&`
        if(this.showAllFolderIfNoneSpecified) result+=`showAllFolderIfNoneSpecified=${this.showAllFolderIfNoneSpecified}&`
        return result;
    }
    public reset(){
        this.search=''
        this.shareMode=null
        this.fromDate=null;
        this.toDate=null;
        this.ext=''
    }
    public get hasFilter():boolean{
        return !!this.search || !!this.shareMode || !!this.fromDate || !!this.toDate || !!this.ext
    }
}