 class Resources {
 private base =  document.location.protocol + `//` + document.location.host;
//  private base =  "https://portal.development.tpssoftware.net";
//  private base =  "https://localhost:8001";
 private version = '/api/{tenantId}/{clientId}'
 public resourceBase = this.base + this.version;
 public authConfigUrl= `${this.base}/api/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000/config/msal`
 public firmswithaccess = this.resourceBase + '/FirmPicker/getfirms';
 public me = this.resourceBase + '/me';
 public options = this.resourceBase + '/portalsettings/options';
 public invoices = this.resourceBase + '/invoices';
 public paymentGateways = this.resourceBase + '/PaymentGateways';
 public client = this.resourceBase + '/client';
 public paymentIntent = this.resourceBase + '/PaymentIntent';
 public publicinvoices = this.resourceBase + '/PublicInvoices';
 public publicpayments = this.resourceBase + '/PublicPayments';
 public stripePayment = this.resourceBase + '/StripePayment';
 public payClixPayment = this.resourceBase + '/PayClixPayment';
 public cpaChargePayment = this.resourceBase + '/AffinityPayPayment';
 public payPalPayment = this.resourceBase + '/PayPalPayment';
 public cloverConnectPayment = this.resourceBase + '/CloverConnectPayment';

 public files = this.resourceBase + '/files';
 public rescent_files = this.resourceBase + '/files/rescent';
 public extensions = this.resourceBase + '/files/extensions';
 public dowloadLink = this.resourceBase + '/files/downloadlink';
 public dowloadLinks = this.resourceBase + '/files/downloadlinks';
 public thumbnaillink = this.resourceBase + '/files/thumbnaillink';
 public fileuploadlink = this.resourceBase + '/upload/getuploadlink';
 public completefileupload = this.resourceBase + '/upload/completefileupload';
 public folders = this.resourceBase + '/folders';
 public setfoldeorfileparent = this.resourceBase + '/folders/changeparent';
 public folderpath = this.resourceBase + '/folders/folderpath';
 public link = this.resourceBase + '/links';

 
}

 export default new Resources();
