<template>
   <div>
    <InvoicePaymentGatewayHandler :isAnonymous="true" @paymentApplied="onPaymentApplied" v-bind="$props"/>
   </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import InvoicePaymentGatewayHandler from '@/components/Payments/InvoicePaymentGatewayHandler.vue'
@Component({
  components: {
    InvoicePaymentGatewayHandler
  },
})
export default class AnonymousInvoicePayment extends Vue{
  @Prop({ required: true })
  public invoiceToken: string;
  onPaymentApplied(){
   
  }
}
</script>