import {httpService} from '@/utils/http';
import Resources from '@/common/resources';
class AccountService {
 
  public getFirmsWithAccess(): Promise<any[]> {
    return httpService.get(`${Resources.firmswithaccess}`);
  }
  public me(): Promise<any> {
    return httpService.get(Resources.me);
  }
  public options(): Promise<any> {
    return httpService.get(Resources.options);
  }

  
}
export let accountService = new AccountService();
