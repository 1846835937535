/**
 * Date filter
 *
 * usage: {{ data | date }}
 */
import Vue from 'vue'
import moment from 'moment'
import accounting from 'accounting'
export default {
  initialize() {
    Vue.filter('date', function (val, format = 'MM/DD/YYYY') {
      return val ? moment(val).format(format) : ''
    })
    Vue.filter('localdate', function (val, format = 'MM/DD/YYYY') {
      return val ? moment.utc(val).local().format(format) : ''
    })
    Vue.filter('localdatetime', function (val, format = 'MMMM DD, YYYY HH:mm') {
      return val ? moment.utc(val).local().format(format) : ''
    })
    Vue.filter('trim', function (val, length = 15, trail = true) {
      if (!length) length = 15
      if (!val) return ''
      val = val.trim();
      if (val && val.length > length) {
        return val.substring(0, length) + (trail ? '...' : '');
      }
      return val;
    });
    Vue.filter('money', function (val) {
      if (!val) val = 0.00
      let currency="$";//todo get currency from firm properties
      let formatted = accounting.formatMoney(val, {
        symbol: currency,
        precision: 2,
        format: {
          pos: '%s%v',
          neg: '%s(%v)',
          zero: '%s%v'
        }})
      return formatted
    
    });
  }
}
