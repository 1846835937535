<template>
    <Trash v-bind="$props"/>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Trash from '@/components/Files/Trash.vue'
@Component({
  components: {
  Trash
  }
})
export default class TrashFolderPage extends Vue{
  
  
}
</script>