<template>
    <PrePaymentGatewayHandler  :isAnonymous="true" @paymentreceived="onPaymentReceived" v-bind="$props"/>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PrePaymentGatewayHandler from '@/components/Payments/PrePaymentGatewayHandler.vue'
@Component({
  components: {
  PrePaymentGatewayHandler
  },
})
export default class AnonymousPrepaymentsPage extends Vue{
  @Prop({ required: true })
  public paymentToken:string;

 created(){
  }
 onPaymentReceived(){
  this.$router.push({name:'anonymousdepositreceived'});
 }
}
</script>