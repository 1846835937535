class Debounce {
    debounce (method: (...params: any[]) => any, wait:number, immediate =false) {
        let timeout: any=null;
        return function () {
          let context = this as any;
          let args = arguments
          clearTimeout(timeout)
          timeout = setTimeout(() => {
          timeout = null
            if (!immediate) method.apply(context, args) 
          }, wait)
          if (immediate && !timeout) method.apply(context, args)
        }
      }
     
     slice = [].slice;
     asyncDebounce(fn: (...params: any[]) => Promise<any>, interval:number) {
        
        let timeout:any=null;
        let running = false;
        let nextArgs:any;
        
        return ()=> {
          let args = this.slice.call(arguments);
          args.push(function() {
            running = false;
            if (nextArgs) {
              run(nextArgs);
              nextArgs = null;
            }
          });
          
          if (running) return nextArgs = args;
          if (timeout) clearTimeout(timeout);
          
          timeout = setTimeout(function() {
            run(args);
            timeout = null;
          }, interval);
          
          function run(args:any) {
            fn.apply(null, args);
            running = true;
          }
        }
      }
     
}
export const debounce =new Debounce()