<template>
  <div class="user">
    <div class="photo">
      <img  :src="$store.getters.currentUser.profilePicture" alt="avatar"/>
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click.stop="toggleMenu">
           <span>
             {{title}}
             <b class="caret"></b>
          </span>
      </a>
      <div class="clearfix"></div>
      <div>
          <ul class="nav" v-show="!isClosed">
            <slot>
              <li>
                <a class="profile-dropdown" target="_blank" :href="myAccountUrl"  >
                  <span class="sidebar-normal"><i class="fa fa-gear"></i> Manage</span>
                </a>
              </li>
              <li>
                <a class="profile-dropdown" href="/logout"  >
                  <span class="sidebar-normal"><i class="fa fa-sign-out"></i> Sign Out</span>
                </a>
              </li>
            </slot>
          </ul>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    components: {
    },
    props: {
      title: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        myAccountUrl:'',
        isClosed: true
      }
    },
    methods: {
      setAccountManageUrl(){
          var currentHostUrl = document.location.protocol + `//` + document.location.host;
          let accountUrl = this.replaceSubdomain(currentHostUrl, 'accounts');
        this.myAccountUrl= accountUrl;
      },
     replaceSubdomain(url, toSubdomain) {
  const replace = "://" + toSubdomain + ".";

  // Prepend http://
  if (!/^\w*:\/\//.test(url)) {
    url = "http://" + url;
  }

  // Check if we got a subdomain in url
  let subdomains = url.match(/\.\w*\b/g)
  if (subdomains && subdomains.length > 1) {
    return url.replace(/(:\/\/\w+\.)/, replace)
  }

  return url.replace(/:\/\/(\w*\.)/, `${replace}$1`)
},
      toggleMenu () {
        this.isClosed = !this.isClosed
      }
    },
    created(){
this.setAccountManageUrl()
    }
  }
</script>
<style>
  .collapsed {
    transition: opacity 1s;
  }
</style>