
import mitt from 'mitt'
const emitter = mitt()
import constants from '@/common/enums'
type GenericCallback = (...args: any[]) => void;

class MessageBuss {
     
  eventTypes= constants.eventTypes
  triggerEvent(event: string, data?:any) {
    emitter.emit(event,data)

  }
  onEvent(event: string, callback: GenericCallback) {
    emitter.on(event, callback )

  }
  offEvent(event: string,  callback: GenericCallback) {
    emitter.off(event, callback)
  }
    
}
export const messageBuss = new MessageBuss();
