<template>
  <b-card title="Folder Details">
    <b-button
      @click="$emit('close')"
      variant="link"
      style="position: absolute; top: 5px; right: 5px"
    >
      <i class="fa fa-close"></i
    ></b-button>
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <br />
        <b-skeleton type="avatar"></b-skeleton>
        <br />
        <br />
        <b-skeleton></b-skeleton>
        <br />
        <br />
        <b-skeleton type="input"></b-skeleton>

        <br />
        <br />
        <b-skeleton type="button"></b-skeleton>
      </template>
<template v-if="file">
      <b-avatar
        class="m-1"
        variant="light"
        :src="getImageForExtension(file.ext)"
      ></b-avatar>
      <b-badge class="text-left d-block m-1">{{
        file.sharedOn | localdate
      }}</b-badge>

      <b-card-text>
        {{ file.displayName }}
      </b-card-text>
      <b-card class="m-1" title="Download History">
        <b-list-group v-if="file.accessHistory.length > 0">
          <b-list-group-item
            v-for="accessHistory in file.accessHistory"
            :key="accessHistory.date"
          >
            {{ accessHistory.date | localdatetime }}
          </b-list-group-item>
        </b-list-group>
        <div v-else>File has not been downloaded</div>
      </b-card>
      </template>
    </b-skeleton-wrapper>
  </b-card>
</template>
<script lang="ts">
import { Component, Prop, Vue,Watch } from "vue-property-decorator";
import { fileService } from "@/utils/http";
@Component({
  components: {},
})
export default class Library extends Vue {
  @Prop({ default: "", required: true })
  fileId: any;
  isLoading = false;
  file: any=null;
 @Watch('fileId') fileIdChanged(value:any, oldValue:any){
   this.loadFile();
  }
  getImageForExtension(extension: string) {
    let imgName = "generic";
    switch (extension) {
      case "pdf":
      case "ps":
      case "eps":
        imgName = "pdf";
        break;
      case "doc":
      case "docx":
      case "docm":
        imgName = "doc";
        break;
      case "xlsx":
      case "xlsm":
      case "xlsb":
      case "xltx":
        imgName = "xls";
        break;
      case "odt":
      case "rtf":
      case "tex":
      case "wpd":
      case "txt":
        imgName = "txt";
        break;
    }
    return `static/img/fileextensions/${imgName}.png`;
  }
  loadFile(){
 this.isLoading = true;
    fileService.getFile(this.fileId).then((file) => {
      this.isLoading = false;
      this.file = file;
    });
  }
  created() {
   this.loadFile()
  }
}
</script>

<style scoped>
</style>