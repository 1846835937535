<template>
  <div class="container-fluid h-100">
    <div class="d-flex justify-content-center align-items-center h-100">
      <b-card>
        <div class="text-center mb-2">
          <b-avatar
            square
            class="m-1"
            v-if="userProperties"
            :src="userProperties.profilePicture"
            size="5rem"
          ></b-avatar>
          <br />
          {{ userProperties && userProperties.name }}
        </div>
        <div class="text-center" v-if="isLoggingIn">
          <b-spinner style="width: 7rem; height: 7rem"></b-spinner>
          <h4>Logging You In</h4>
        </div>
        <template v-else-if="loginFailed">
          Something went wrong during the login. Please try again.
          <hr />
          <router-link to="/login">Try Again</router-link>
        </template>
        <template v-else-if="isLoggedin">
          <div v-if="progressStatus == 'firms'">
            <div class="text-center" v-if="!areFirmsLoaded">
              <b-spinner></b-spinner>
              <h4>Determining firms you have access</h4>
            </div>
            <b-list-group v-else style="max-height: 300px; overflow: auto">
              <b-list-group-item  class="flex-column align-items-start" style="min-width:300px">
                <input type="text" @input="filterChanged" v-model="firmSearchTerm" class="form-control border border-primary" placeholder="Search by firm name or client name"/>

              </b-list-group-item>
              <b-list-group-item
                class="flex-column align-items-start" style="min-width:300px"
               
                v-for="firm in filteredFirms"
                :key="firm.id"
              >
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">{{ firm.displayName }}</h5>
                  <small v-if="firm.hasPortal"><b-button variant="success" size="sm"  @click="selectAndLoadFirmDetails(firm)">Open</b-button></small>
                </div>

                <small>{{ firm.clientName }}</small>
                <div v-if="!firm.hasPortal">
                  <b-badge class="mr-1 d-block" :variant="'danger'">
                    Firm has disabled this feature
                  </b-badge>
                </div>
              </b-list-group-item>
            </b-list-group>

            <hr />
            <b-button class="pull-right" variant="primary" @click="logout"
              >Logout</b-button
            >
          </div>
          <div
            class="text-center"
            v-else-if="progressStatus == 'accountdetails'"
          >
            <b-spinner style="width: 7rem; height: 7rem"></b-spinner>
            <h4>Loading Account Details</h4>
          </div>
        </template>
      </b-card>
    </div>
  </div>
</template>

<script lang="ts">
import resources from "../../common/resources";
import { accountService } from "../../utils/http";
import { Component, Prop, Vue } from "vue-property-decorator";
import { debounce } from "@/utils";

import store from "@/store";
@Component({
  components: {},
})
export default class AccountPicker extends Vue {
  userProperties = null;
  isLoggedin = false;
  isLoggingIn = false;
  loginFailed = false;
  firms = [];
  filteredFirms=[];
  progressStatus = "";
  areFirmsLoaded = false;
  firmSearchTerm='';
  constructor(){
    super()
        this.filterChanged= debounce.debounce(this.filterChanged,500);

  }
  async created() {
    //reset current firm
    this.$store.commit("setCurrentFirmAndClient", null);
    //@ts-ignore
    if (this.$msal.isAuthenticated) {
      this.isLoggedin = true;
      this.loginFailed = false;
      this.isLoggingIn = false;
      await this.loadUserProperties();
      this.loadFirms();
    } else {
      this.isLoggingIn = true;
      //@ts-ignore
      this.$msal.listenFormRedirectPromise(async () => {
        this.isLoggingIn = false;
        this.isLoggedin = true;
        //@ts-ignore
        if (this.$msal.isAuthenticated) {
          this.isLoggedin = true;
          this.isLoggingIn = false;
          await this.loadUserProperties();
          this.loadFirms();
        } else {
          this.loginFailed = true;
          this.navigateToLogin();
        }
      });
    }
  }
  async redirectToApp() {
    if (window.history.replaceState) {
      window.history.replaceState({}, "TPSPortal", "/");
      //@ts-ignore
      window.location.reload(true);
    } else {
      window.location.replace(
        document.location.protocol + `//` + document.location.host
      );
    }
  }
  filterChanged(){
    if(!this.firms) this.filteredFirms=[];
    if(!this.firmSearchTerm)this.filteredFirms=[...this.firms];
    let searchTerm=this.firmSearchTerm.toLowerCase();
    this.filteredFirms=this.firms.filter(z=>(z.clientName && z.clientName.toLowerCase().includes(searchTerm)) || z.displayName.toLowerCase().includes(searchTerm));
  }
  selectAndLoadFirmDetails(firm) {
    if (!firm.hasPortal) return;
    this.progressStatus = "accountdetails";
    this.$store.commit("setCurrentFirmAndClient", {
      firmId: firm.id,
      firmName: firm.displayName,
      clientName: firm.clientName,
      clientId: firm.clientId,
    });
    this.$store.commit("setCurrentUser", this.userProperties);
   

    this.redirectToApp();
  }
  async login() {
    //@ts-ignore
    await this.$msal.signIn();
  }
  logout() {
    //@ts-ignore
    this.$msal.signOut();
  }
  getToken() {
    //@ts-ignore
    this.$msal.acquireToken();
  }

  get firmsWithPortal(){
    if(!this.firms) return [];
    return this.firms.filter(z=>z.hasPortal)
  }
  async loadFirms() {
    this.progressStatus = "firms";
    accountService
      .getFirmsWithAccess()
      .then((result) => {
       
        this.firms = result.sort(this.firmSortFunction);
        this.filteredFirms=this.firms
        store.commit('firms',this.firmsWithPortal);
        this.areFirmsLoaded = true;
        if (this.firmsWithPortal.length == 1) {
          this.selectAndLoadFirmDetails(this.firmsWithPortal[0]);
        }
      })
      .catch((err) => console.log("Error loading firms", err));
  }
  loadFirmsIfAuthenticated() {
    //@ts-ignore
    if (this.$msal.isAuthenticated) {
      this.loadFirms();
    }
  }
  async loadUserProperties() {
    //@ts-ignore
    // this.userProperties = await this.$msal.getUserProperties();
    var user= await  accountService.me()
    if(!user.profilePicture){
       user.profilePicture='https://eu.ui-avatars.com/api/?background=random&name=' +  user.name
    }
    this.userProperties=user;
              
  }
  
firmSortFunction(a, b) {          
      if (a.displayName === b.displayName) {
         return a.clientName > b.clientName ? 1 : -1;
      }
      return a.displayName > b.displayName ? 1 : -1;
   }
   navigateToLogin(){
      this.$router.push({ name: "login" });
    }
}
</script>
<style lang="scss" scoped>
</style>
