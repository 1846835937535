<template>

    <div class="container-fluid h-100">
      <div class="row no-gutters h-100">
        <div  class="h-100 col-12">
          <ContentLayout>
            <template #header>
              <input
                type="file"
                style="display: none"
                multiple
                ref="filePicker"
                @change="filePickerChanged($event)"
              />
              <b-dropdown
                class="d-inline-block mr-2"
                variant="success"
                no-caret
              >
                <template #button-content>
                  <i class="fa fa-plus"></i>
                </template>
                <!-- //TODO Check permissions -->
                <b-dropdown-item @click="createFolder" 
                  >New Folder
                </b-dropdown-item>
                <b-dropdown-item @click="newFileUpload"
                  >File Upload
                </b-dropdown-item>
              </b-dropdown>
              <b-button-toolbar
                class="d-inline-block m-2"
                
              >
              <b-button title="Refresh Data" variant="link" @click="loadData">
                   <i class="fa fa-refresh"></i>
                  </b-button>
                <b-button-group v-if="files.length > 0" class="mx-1">
                
                  <b-button variant="link" @click="selectAllOrNone">
                    {{ areAllSelected ? "Unselect All" : "Select All" }}
                  </b-button>
                </b-button-group>
                <b-button-group class="mx-1" v-if="isAnythingSelected">
                  <!-- //TODO Check permissions -->
                  <b-button  variant="primary" @click="setFilesToBeMoved"
                    >Move
                  </b-button>
                  <b-button variant="primary" @click="downloadSelectedFiles"
                    ><i class="fa fa-download"></i> Download Selected
                  </b-button>
                  <!-- <b-button>Undo</b-button>
                  <b-button>Redo</b-button> -->
                </b-button-group>
                <b-button-group class="mx-1" v-if="canMoveFilesHere">
                  <b-button variant="success" @click="moveFilesInCurrentFolder"
                    >Move Selected Files Here
                  </b-button>
                  <!-- <b-button>Undo</b-button>
                  <b-button>Redo</b-button> -->
                </b-button-group>

                <b-button-group class="mx-1">
                  <b-button variant="link" @click="isListMode = !isListMode">
                    <i v-if="isListMode" class="fa fa-th-large"></i>
                    <i v-else class="fa fa-list"></i>
                  </b-button>
                
                   <!-- <b-button variant="link"  @click="showFilter=!showFilter;">
                   {{showFilter?'Hide Filter': 'Show Filter'}}
                  </b-button> -->
                  
                </b-button-group>
                
              </b-button-toolbar>
            
              <FolderPath :folderId="folderId" />
              <div>
                <FileFilterComponent  @onFilter="onFilter" /> 
               <Sort @onSort="onSort"></Sort>
              
              </div>
            </template>
              <b-skeleton-wrapper :loading="isLoading">
    <template #loading>
      <b-card-group deck>
        <b-card class="skeleton-card" v-for="index in 10" :key="index">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </b-card-group>
    </template>
 <template v-if="folders.length > 0">
              <b-card-group v-if="!isListMode" class="m-0" deck > 
                <!-- //TODO Check permissions -->
                <div
                  v-for="file in folders"
                  :key="file.id"
                 
                  :draggable="true"
                  @dragover.prevent
                  @dragenter.prevent="handleDragEnter"
                  @dragleave="handleDragLeave"
                  @drop="onDrop($event, file)"
                  @dragstart="startDrag($event, file)"
                  @dragend="endDrag($event)"
                  class="dropable"
                >
                  <card-file-item :showSelection="true"
                    @selected="currentSelectedItem = file.id"
                    :file="file"
                  />
                </div>
              </b-card-group>
              <!-- //TODO Check permissions -->
              <b-list-group v-else>
                <div
                  v-for="file in folders"
                  :key="file.id"
                  :draggable="true"
                  @dragover.prevent
                  @dragenter.prevent="handleDragEnter"
                  @dragleave="handleDragLeave"
                  @drop="onDrop($event, file)"
                  @dragstart="startDrag($event, file)"
                  @dragend="endDrag($event)"
                  class="dropable"
                >
                  <list-file-item :showSelection="true"
                    @selected="currentSelectedItem = file.id"
                    :file="file"
                  />
                </div>
              </b-list-group>
            </template>
            <div @dragover="onExternalFilesDragOver" @drop="onExternalFilesDropped" style="min-height:200px;width:100%">
          
              <template v-if="visibleFiles.length > 0">
                <b-card-group class="m-0" deck v-if="!isListMode">
                  <!-- //TODO Check permissions -->
                  <div
                    v-for="file in visibleFiles"
                    :key="file.id"
                    :draggable="true"
                    @dragstart="startDrag($event, file)"
                    @dragend="endDrag($event)"
                  >
                    <card-file-item @edit="showEditScreen" style="width:100px"  :showSelection="true" :file="file" />
                  </div>
                </b-card-group>
                <b-list-group v-else>
                  <div
                    v-for="file in visibleFiles"
                    :key="file.id"
                    :draggable="true"
                    @dragstart="startDrag($event, file)"
                    @dragend="endDrag($event)"
                    @edit="showEditScreen"
                  >
                    <list-file-item :showSelection="true" :file="file" />
                  </div>
                </b-list-group>
              </template>
              <template v-else>
                <b-card title="No Files">
                  <b-card-sub-title v-if="filter && filter.hasFilter">
                    Try changing your filters
                  </b-card-sub-title>
                  <b-card-text>
                    You don't seem to have any files in this folder. Click the
                    new button to add new files or folder or drag and drop here

                  </b-card-text>
                </b-card>
              </template>
              
           
            </div>
              </b-skeleton-wrapper>
          </ContentLayout>
        </div>
      <b-modal v-if="showEditUI" v-model="showEditUI" hide-header hide-footer>
       <EditFile :fileId="editingfileId" @updated="fileUpdated"  @cancel="showEditUI=false"></EditFile>
      </b-modal>
      </div>
    </div>
</template>
<script lang="ts">
import {
  defineComponent,
  toRefs,
  ref,
  computed,
  watch,
  onBeforeUnmount,
} from "vue";
import { fileService } from "@/utils/http";
import CardFileItem from "./common/CardFileItem.vue";
import ListFileItem from "./common/ListFileItem.vue";
import EditFile from "./common/EditFile.vue";
import ContentLayout from "@/components/layouts/ContentLayout.vue";
import FolderPath from "@/components/Files/common/FolderPath.vue";
import FileFilterComponent from "./common/Filter.vue";
import { FileFilter } from "@/common/models";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { BlockBlobClient } from "@azure/storage-blob";
import Sort from "./common/Sort.vue";
import { messageBuss, messageService } from "@/utils/";
import constants from "@/common/enums";
export default defineComponent({
  components: {
    EditFile,
    FileFilterComponent,
    FolderPath,
    ListFileItem,
    CardFileItem,
    ContentLayout,
    Sort,
  },
  props: { folderId: { required: false, default: "", type: String } },
  setup(props, ctx) {
    const filePicker = ref(null);
    const { folderId } = toRefs(props);
    const editingfileId = ref("");
    const showEditUI = ref(false);
    const files = ref<any[]>([]);
    const folders = ref<any[]>([]);
    const isLoading = ref(false);
    const currentSelectedItem = ref("");
    const isListMode = ref(false);
    const showFolderDetails = ref(false);
    const showFilter = ref(false);
    const filter = ref<FileFilter>(null);
    const sortOptions = ref(null);
    const moveContext = ref<{
      originalFolderId: string;
      filesOrFolders: string[];
    }>(null);
    const canMoveFilesHere = computed(() => {
      if (!moveContext.value) return false;
      return moveContext.value.originalFolderId != folderId.value;
    });
    const visibleFiles = computed(() => {
      let filterPredicate = filterFunc(filter.value);
      return files.value.filter(filterPredicate);
    });
    const areAllSelected = computed(() => {
      return files.value.every((z) => z.selected);
    });
    const isAnythingSelected = computed(() => {
      return files.value.some((z) => z.selected);
    });
    const onFilter = (newFilter: FileFilter) => {
      filter.value = newFilter;
    };
    const onSort = (value) => {
      
      sortOptions.value = value;
      sortFiles();
      sortFolders();
    };
    const sortFiles = () => {
      sortData(files.value);
    };
    const sortFolders = () => {
      sortData(folders.value);
    };
    const filterFunc = (filter: FileFilter) => {
      if (!filter) return (item) => true;
      return (item) => {
        if (
          filter.search &&
          item.name.toLowerCase().indexOf(filter.search.toLowerCase()) === -1
        ) {
          return false;
        }
        if (filter.shareMode == 2 && item.isReceived) {
          return false;
        }
        if (filter.shareMode == 1 && !item.isReceived) {
          return false;
        }
        if (filter.ext && filter.ext != item.ext) {
          return false;
        }
        return true;
      };
    };
    const sortData = (data) => {
      const { property, type } = sortOptions.value.column;
      data.sort((a, b) => {
        let valA = a[property];
        let valB = b[property];
        if (type === "date") {
          valA = new Date(valA).getTime();
          valB = new Date(valB).getTime();
        } else if (type === "string") {
          valA = valA.toLowerCase();
          valB = valB.toLowerCase();
        }
        if (valA < valB) {
          return sortOptions.value.order === "asc" ? -1 : 1;
        }
        if (valA > valB) {
          return sortOptions.value.order === "asc" ? 1 : -1;
        }
        return 0;
      });
    };
    const fileUpdated = () => {
      showEditUI.value = false;
      loadData();
    };
    const showEditScreen = (fileId: string) => {
      editingfileId.value = fileId;
      showEditUI.value = true;
    };
    const newFileUpload = () => {
      if (filePicker.value) {
        filePicker.value.click();  // Trigger the click event on the input
      }
    };
    const onExternalFilesDragOver = (ev) => {
      ev.preventDefault();
    };
    const onExternalFilesDropped = (ev) => {
      ev.preventDefault();
      ev.preventDefault();
      if (ev.dataTransfer.items) {
        let files = [];
        for (var i = 0; i < ev.dataTransfer.items.length; i++) {
          if (ev.dataTransfer.items[i].kind === "file") {
            var file = ev.dataTransfer.items[i].getAsFile();
            files.push(file);
          }
        }
        if (files.length > 0) {
          messageBuss.triggerEvent(constants.eventTypes.QueueFileUpload, {
            files: files,
            folderId: folderId.value,
          });
        }
      }
    };
    const createFolder = async () => {
      var folderName = await messageService.askTextInput(
        "New Folder",
        "Folder Name",
        true,
        "New Folder"
      );
      if (!folderName) return;
      await fileService.createFolder(folderName, folderId.value);
      loadFolders();
    };
    const moveFilesInCurrentFolder = async () => {
      isLoading.value = true;
      await fileService.setParent(
        moveContext.value.filesOrFolders,
        folderId.value || ""
      );
      isLoading.value = false;
      moveContext.value = null;
      loadData();
    };
    const setFilesToBeMoved = () => {
      if (!isAnythingSelected.value) return;
      moveContext.value = {
        originalFolderId: folderId.value,
        filesOrFolders: files.value.filter((z) => z.selected).map((z) => z.id),
      };
      messageService.showToastInfo(
        "Files copied. Navigate to the folder you want to move and click the Move Files Here button"
      );
    };
    const downloadSelectedFiles = async () => {
      if (!isAnythingSelected.value)
        return messageService.showToastInfo("Nothing selected to download");
      var selectedFileIds = files.value
        .filter((z) => z.selected)
        .map((z) => z.id);
      if (selectedFileIds.length == 0) return;
      messageService.showLoading("Generating File Links");
      let links = await fileService.getDownloadLinks(selectedFileIds);
      messageService.closeDialogs();
      messageService.showLoading("Downloading Files");
      downloadAndSaveFilesIntoZip("download.zip", links);
    };
    const downloadAndSaveFilesIntoZip = async (filename, urls) => {
      if (!urls) return;
      const zip = new JSZip();
      let downloadPromises = urls.map((url) =>
        downloadAndAddToZipFolder(zip, url)
      );
      await Promise.all(downloadPromises);
      zip.generateAsync({ type: "blob" }).then((blob) => {
        saveAs(blob, filename);
        messageService.closeDialogs();
      });
    };
    const downloadAndAddToZipFolder = async (folder: JSZip, url) => {
      const blobClient = new BlockBlobClient(url.link);
      var file = await blobClient.download();
      folder.file(url.name, file.blobBody);
    };
    const filePickerChanged = (event) => {
      var files = [...event.target.files];
      messageBuss.triggerEvent(constants.eventTypes.QueueFileUpload, {
        files: files,
        folderId: folderId.value,
      });
    };
    const folderchanged = () => {
      loadData();
    };
    const selectAllOrNone = () => {
      let shouldSelect = !areAllSelected.value;
      files.value.forEach((z) => (z.selected = shouldSelect));
    };
    const loadFiles = () => {
      isLoading.value = true;
      let queryFilter = "";
      if (!filter.value) {
        queryFilter = new FileFilter().getQueryString(folderId.value);
      } else {
        queryFilter = filter.value.getQueryString(folderId.value);
      }
      fileService.getfiles(queryFilter).then((result) => {
        result.forEach((file) => {
          file.selected = false;
        });
        files.value = result;
        sortFiles();
        isLoading.value = false;
      });
    };
    const loadFolders = () => {
      isLoading.value = true;
      fileService.getFolders(folderId.value).then((result) => {
        result.forEach((file) => {
          file.isFolder = true;
        });
        folders.value = result;
        sortFolders();
        isLoading.value = false;
      });
    };
    const reset = () => {
      folderId.value = null;
      files.value = [];
      folders.value = [];
      moveContext.value = null;
      loadData();
    };
    const loadData = () => {
      loadFiles();
      loadFolders();
    };
    const fileUploaded = () => {
      loadFiles();
    };
    const fileDeleted = () => {
      loadFiles();
    };
    const handleDragEnter = (evt: DragEvent) => {
      addClassToEvent(evt, "drag-over");
    };
    const handleDragLeave = (evt: DragEvent) => {
      removeClassFromEvent(evt, "drag-over");
    };
    const endDrag = (evt: DragEvent) => {
      //@ts-ignore
      evt.srcElement.style.opacity = 1;
    };
    const startDrag = (evt: DragEvent, item) => {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData(
        "tps-drag-file-ids",
        [
          item.id,
          ...files.value.filter((z) => z.selected).map((z) => z.id),
        ].join()
      );
      //@ts-ignore
      evt.srcElement.style.opacity = 0.2;
    };
    const addClassToEvent = (evt: DragEvent, className: string) => {
      //@ts-ignore
      evt.srcElement.classList.add(className);
    };
    const removeClassFromEvent = (evt: DragEvent, className: string) => {
      //@ts-ignore
      evt.srcElement.classList.remove(className);
    };
    const onDrop = (evt, currentFolder) => {
      removeClassFromEvent(evt, "drag-over");
      const dragData = evt.dataTransfer.getData("tps-drag-file-ids");
      evt.dataTransfer.setData("tps-drag-file-ids", "");
      if (!dragData) return;
      let fileIds = dragData.split(",");
      fileService.setParent(fileIds, currentFolder.id).then(() => {
        filesMoved({fileIds:fileIds, folderId:currentFolder.id});
      });
    };
    const filesMoved = (data:{fileIds:string[],folderId:string}) => {
      if (!data || !data.fileIds) return;
      loadData();
    };
    watch(folderId, folderchanged);
    (() => {
      messageBuss.onEvent(constants.eventTypes.FirmChanged, reset);
      messageBuss.onEvent(constants.eventTypes.FileUploaded, fileUploaded);
      messageBuss.onEvent(constants.eventTypes.FileDeleted, fileDeleted);
      messageBuss.onEvent(constants.eventTypes.FilesMoved, filesMoved);
      messageBuss.onEvent(constants.eventTypes.FolderDeleted, loadFolders);
      messageBuss.onEvent(constants.eventTypes.FolderUpdated, loadFolders);
      loadData();
    })();
    onBeforeUnmount(() => {
      messageBuss.offEvent(constants.eventTypes.FirmChanged, reset);
      messageBuss.offEvent(constants.eventTypes.FileUploaded, fileUploaded);
      messageBuss.offEvent(constants.eventTypes.FileDeleted, fileDeleted);
      messageBuss.offEvent(constants.eventTypes.FilesMoved, filesMoved);
      messageBuss.offEvent(constants.eventTypes.FolderDeleted, loadFolders);
      messageBuss.offEvent(constants.eventTypes.FolderUpdated, loadFolders);
    });
    return {
      filePicker,
      editingfileId,
      showEditUI,
      files,
      folders,
      isLoading,
      currentSelectedItem,
      isListMode,
      showFolderDetails,
      showFilter,
      filter,
      sortOptions,
      moveContext,
      canMoveFilesHere,
      visibleFiles,
      areAllSelected,
      isAnythingSelected,
      onFilter,
      onSort,
      sortFiles,
      sortFolders,
      filterFunc,
      sortData,
      fileUpdated,
      showEditScreen,
      newFileUpload,
      onExternalFilesDragOver,
      onExternalFilesDropped,
      createFolder,
      moveFilesInCurrentFolder,
      setFilesToBeMoved,
      downloadSelectedFiles,
      downloadAndSaveFilesIntoZip,
      downloadAndAddToZipFolder,
      filePickerChanged,
      folderchanged,
      selectAllOrNone,
      loadFiles,
      loadFolders,
      reset,
      loadData,
      fileUploaded,
      fileDeleted,
      handleDragEnter,
      handleDragLeave,
      endDrag,
      startDrag,
      addClassToEvent,
      removeClassFromEvent,
      onDrop,
      filesMoved,
    };
  },
});
</script>
<style scoped>
.skeleton-card {
  max-width: 200px;
  min-width: 200px;
  margin: 20px;
}

.drag-over  {
  border: solid 2px #87cb16!important;
}
.dropable {
}
</style>